<script>
  import { t } from '../../utilities/general.js';

  export let item;        

  const text = t(item.rule, 'text');
</script>

<style>
  .wizard-ruleset-item {
    padding: 30px 25px;
    background-color: #f0f0f0;
    font-size: 1.25rem;
    line-height: 1.25;
  }
</style>

<p class="wizard-ruleset-item">{@html text}</p>
