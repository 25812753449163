<script>
  export let progress = 1,
    disabled,
    skipped;
</script>

<div class="circle-wrap">
  {#if progress < 1}
    <svg height="36" width="36" style="stroke-dashoffset: 0px" class:text-lightgrey={disabled || skipped}>
      <circle class="base" cx="18" cy="18" r="15" stroke-width="3" fill-opacity="0" />
    </svg>
  {/if}
  {#if skipped}
    <svg height="36" width="36" style="stroke-dashoffset: 0px">
      <circle cx="18" cy="18" r="15" stroke="#ccc" stroke-width="3" fill-opacity="0" />
    </svg>
  {:else}
    <svg height="36" width="36" style="stroke-dashoffset: {(1 - progress) * 95}px">
      <circle class="progress" cx="18" cy="18" r="15" stroke-width="3" fill-opacity="0" />
    </svg>
  {/if}
  <div class="inside-circle" class:text-lightgrey={disabled || skipped} class:text-green={progress == 1}>
    {#if skipped}
      <span class="fa fa-minus text-disabled" />
    {:else}
      <slot />
    {/if}
  </div>
</div>

<style>
  circle.base {
    stroke: var(--primary, #da8b31);
  }
  .text-lightgrey circle.base {
    stroke: lightgrey;
  }
  circle.progress {
    stroke: var(--progress);
  }
  .inside-circle {
    --iconFillColor: var(--primary, #da8b31);
  }
  .inside-circle.text-lightgrey {
    --iconFillColor: lightgrey;
  }
  .inside-circle.text-green {
    --iconFillColor: #a1ea2a;
  }
</style>
