<script>
  import I18n from '../../utilities/I18n.js';
  import Select from '../generic/Select.svelte';
  import WarningPanel from './WarningPanel.svelte';

  export let choices, 
             selected, 
             name, 
             id;
</script>

<style>
  #constructionTypeWarning {
    margin-top: -24px;
    margin-bottom: 48px;
  }
</style>

<div class="form-group integer required {id}">
  <label class="integer required" for={id}>{I18n.t('activerecord.attributes.project.construction_type')}
    <abbr title={I18n.t('simple_form.required.text')}>{I18n.t('simple_form.required.mark')}</abbr>
  </label>
  <Select enabled=true {choices} bind:selected {name} {id} required={true}  />
</div>

{#if selected && selected.warning}
  <div id="constructionTypeWarning">
    <WarningPanel>
      {@html selected.warning}
    </WarningPanel>
  </div>
{/if}

