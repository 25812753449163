<script>
  export let question, 
             enabled = true, 
             answer;
             
  import { createEventDispatcher } from 'svelte';
  import I18n from '../../utilities/I18n.js';

  const dispatch = createEventDispatcher();

  let name = `question_${question.code}`;

  const toggle = (v) => {
    console.log('toggle bool', enabled, v);
    answer = v;
    if(!enabled)
    {
      dispatch('setCurrent', { id: question.id, code: question.code, answer: answer });
    } else {
      dispatch('answer', { id: question.id, code: question.code, answer: answer });
    }
  }

  // const checked = 'fal fa-times-square fa-2x', unchecked = 'fal fa-square fa-2x';
  const checked = 'bool-checked', unchecked = 'bool-unchecked';
  $: console.log('bool question', question);
</script>

<style>


</style>

<div class="bool-question" class:enabled={enabled}>
  <span class='control' on:click|stopPropagation={() => toggle(true)}>
    <span id={`${name}_1`} class={ answer == true ? checked : unchecked} />
    <label class='ml-2' >{I18n.t('yes')}</label>
  </span>
  <span class='control ml-4' on:click|stopPropagation={() => toggle(false)}>
    <span id={`${name}_0`} class={ answer == false ? checked : unchecked} />
    <label class='ml-2' >{I18n.t('no')}</label>
  </span>
</div>