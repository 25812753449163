<script>
  import I18n from '../../utilities/I18n.js';
  import Select from '../generic/Select.svelte';

  export let choices, 
             selected, 
             name, 
             id;
</script>

<div class="form-group integer required {id}">
  <label class="integer required" for={id}>{I18n.t('activerecord.attributes.project.usage_configuration')}</label>
  <abbr title="required">*</abbr>
  <div class='help mb-3'>{@html I18n.t('usage_configuration_warning_html')}</div>
  <Select enabled=true {choices} bind:selected={selected} {name} {id} required={true}  />
</div>

