export class ApiPaths {
  constructor(apiPath) {
    this.apiUrl = apiPath;
  }

  answerQuestion(questionId) {
    return `${this.apiUrl}/answers/${questionId}`;
  }

  currentQuestion() {
    return `${this.apiUrl}/current_question`;
  }

  building() {
    return `${this.apiUrl}/building`;
  }

  project() {
    return `${this.apiUrl}`;
  }

  wizardSession() {
    return `${this.apiUrl}`;
  }

  answerProofItem(id) {
    return `${this.apiUrl}/proof/items/${id}/answer`
  }

  createProofItem() {
    return `${this.apiUrl}/proof/items`;
  }

  updateProofItem(id) {
    return `${this.apiUrl}/proof/items/${id}`;
  }
}

export class StepPaths {
  constructor(projectPath) {
    this.basePath = projectPath;
  }

  for(step) {
    return `${this.basePath}/${step}`;
  }
}

