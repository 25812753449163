<script>
  import Select from "svelte-select/src/Select.svelte";
  import SelectItem from "./SelectItem.svelte";
  import I18n from "../../utilities/I18n.js";
  import { handleFormInputChange } from "../../frontend/form";

  export let enabled,
    choices,
    selected,
    name,
    placeholder = I18n.t("helpers.select.prompt"),
    id,
    required = false;

  for (let c in choices) {
    if (`${choices[c].value}` == `${selected}`) {
      selected = choices[c];
    }
  }

  let inputAttributes, inputElement;
  if (name) {
    inputAttributes = { name: name };
  }

  $: value = selected ? selected.value : "";
</script>

<div class="select" class:enabled>
  <Select
    {placeholder}
    isDisabled={!enabled}
    items={choices}
    bind:value={selected}
    on:select
    on:select={() => handleFormInputChange({ target: inputElement })}
    hideEmptyState
    isClearable={false}
    isSearchable={false}
    Item={SelectItem} />
</div>

{#if name}
  <input bind:this={inputElement} class:required type="hidden" {name} bind:value {id} />
{/if}

<style>
  .select {
    --border: none;
    --inputFontSize: 16px;
    --disabledColor: #333;
    --disabledBackground: white;
    --disabledBorder: none;
    --itemIsActiveBG: var(--primary, #da8b31);
    --itemHoverBG: #efefef;
    --borderRadius: 0;
    --itemFirstBorderRadius: 0;
    --itemIsActiveColor: white;
    --listShadow: 1px 1px 10px #ccc;
    --listMaxHeight: 25vh;
    --height: 35px;
    --indicatorTop: 5px;
    --indicatorRight: 0;
  }

  .select.enabled {
    border-bottom: var(--selectBorder, 1px solid var(--primary, #da8b31));
  }
</style>
