import I18n from '../utilities/I18n.js';

const filledOut = (el) => {
  return $(el).val() && true;
}

const formElements = (formEl) => {
  return $(formEl).find('input[type!=submit], select');
}

const requiredFilledOut = (formEl) => {
  var ok = true;
  formElements(formEl).filter('.required')
                      .each((idx, el) => { ok = ok && filledOut(el) });
  return ok;
}

const disableSubmitButton = (form) => {
  console.log("disableSubmitButton", form);
  $(form)
    .find("input[type=submit]")
    .attr("disabled", requiredFilledOut(form) ? null : "disabled");
}

export const handleFormInputChange = (event) => {
  disableSubmitButton($(event.target).parents('form'));
}

$(() => {
  $('form.dynamic-submit input[type!=submit], form.dynamic-submit select').change(handleFormInputChange);
  $("form.dynamic-submit").each((idx, el) => disableSubmitButton(el));
})
