<script>
  import I18n from '../../utilities/I18n.js';

  export let icon, 
             label, 
             url, 
             active = true, 
             target='_self';

  const handleClick = () => {
    if (target == '_blank') {
      window.open(url, target);
    }
    else {
      window.location.href = url;
    }
  }

  $: textClass = active ? 'text-black' : 'text-gray';
</script>

<div class='nav-item home-nav' class:active>
  <div class='circle-wrap'>
    <div class="inside-icon">
      <span class="link {icon}"></span>
    </div>
  </div>
  <a class="{textClass} link"  href={url} on:click|preventDefault={handleClick}>
    {label}
  </a>
</div>
