<script>
  export let isActive = false;
  export let isFirst = false;
  export let isHover = false;
  export let getOptionLabel = undefined;
  export let item = undefined;
  export let filterText = '';
  let itemClasses = '';
  $: {
    const classes = [];
    if (isActive) { classes.push('active'); }
    if (isFirst) { classes.push('first'); }
    if (isHover) { classes.push('hover'); }
    if (item.isGroupHeader) { classes.push('groupHeader'); }
    if (item.isGroupItem) { classes.push('groupItem'); }
    itemClasses = classes.join(' ');
  }
</script>

<style>
  .item-container {
    display: flex;
    flex-direction: row;
    padding: var(--itemPadding, 10px 20px);
    align-items: center;
  }
  .item {
    flex-grow: 1;
    cursor: default;
    color: var(--itemColor, inherit);
    text-overflow: ellipsis;
  }
  .groupHeader {
    text-transform: var(--groupTitleTextTransform, uppercase);
  }
  .groupItem {
    padding-left: var(--groupItemPaddingLeft, 40px);
  }
  .item-container:active {
    background: var(--itemActiveBackground, #b9daff);
  }
  .item-container.active, .item-container.active .question-help {
    background: var(--itemIsActiveBG, #007aff);
    color: var(--itemIsActiveColor, #fff);
  }
  .item-container.first {
    border-radius: var(--itemFirstBorderRadius, 4px 4px 0 0);
  }
  .item-container.hover:not(.active) {
    background: var(--itemHoverBG, #e7f2ff);
  }
  .question-help {
    display: block;
    margin: 0;
    white-space: normal;
  }
  .icon {
    padding-left: inherit;
  }
  .icon img {
    height: 2.5rem;
    width: 2.5rem;
  }
</style>


<div class="item-container {itemClasses}">

  <div class="item">
    {@html getOptionLabel(item, filterText)}
    {#if item.help}<small class="question-help">{@html item.help}</small>{/if}
  </div>

  {#if item.iconPath}
    <div class="icon">
      <img src="{item.iconPath}" alt="" />
    </div>
  {/if}

</div>