<script>
  import { fade } from 'svelte/transition';
</script>
<style>

.loading-spinner {
  animation-play-state: running;
  opacity: 1;
  position: relative;
  height: 100vh;
}

@keyframes spinner {
  0% {
    transform: translate3d(-50%, -50%, 0) rotate(0deg);
  }
  100% {
    transform: translate3d(-50%, -50%, 0) rotate(360deg);
  }
}

.loading-spinner::before {
  animation: 1.5s linear infinite spinner;
  animation-play-state: inherit;
  border: solid 3px #f6f5f6;
  border-bottom-color: var(--primary, #DC8A15);
  border-radius: 50%;
  content: "";
  height: 40px;
  left: 50%;
  opacity: inherit;
  position: absolute;
  top: 50%;
  transform: translate3d(-50%, -50%, 0);
  width: 40px;
  will-change: transform;
}

</style>

<div class='overlay' in:fade out:fade>
  <div class="loading-spinner"></div>
</div>
