<script>
  import UsageConfigurationInput from '../eheureka/UsageConfigurationInput.svelte';
  import OtherUsagesInput from '../eheureka/OtherUsagesInput.svelte';
  import OtherUsagesSelectionInput from '../eheureka/OtherUsagesSelectionInput.svelte';
  import MainUsageSelectionInput from '../eheureka/MainUsageSelectionInput.svelte';
  import FloorUsagesInput from '../eheureka/FloorUsagesInput.svelte';
  import { ApiPaths, StepPaths } from "../../utilities/paths.js";
  import { getCSRFToken } from '../../utilities/html.js'
  import { fade } from 'svelte/transition';
  import Spinner from "../generic/Spinner.svelte";

  import WarningPanel from '../eheureka/WarningPanel.svelte';
  import InfoPanel from '../eheureka/InfoPanel.svelte';

  import I18n from '../../utilities/I18n.js';

  export let project,
             apiBaseUrl,
             baseUrl,
             usages,
             primary_usage_ids,
             secondary_usage_ids;

  const stepPaths = new StepPaths(baseUrl);
  const apiPaths = new ApiPaths(apiBaseUrl);

  const primaryUsages = usages.filter(u => primary_usage_ids.includes(u.id));

  let floorUsageComponent;

  usages = usages.map((usage) => {
    usage.active = false;
    usage.value = usage.id;
    usage.label = usage[`name_${I18n.locale}`]
    return usage;
  })

  const getUsageById = (id) => {
    return usages.find(u => u.id === id);
  }

  const loadSelectedOtherUsages = () => {
    let usageIds = [];
    let selectedUsages = []
    if (project.building && project.building.floors) {
      for (const f of floors) {
        if (f.floor_usages) usageIds.push(...f.floor_usages.map(fu => fu.usage_id))
      }
      usageIds = usageIds.filter((value, index, array) => array.indexOf(value) === index);
      for (const uid of usageIds) {
        let usage = usages.find(u => u.id === uid);
        if (usage && usage.value !== selectedMainUsageId) {
          usage.active = true;
          selectedUsages.push(usage)
        }
      }
    }
    return selectedUsages;
  }

  export const sendApiRequest = (url, options) => {
    return fetch(url, options)
    .then((response) => {
      console.log('fetch response', response);

      if (response.ok) {
        return response.json()
      } else {
        console.error('response is not ok', response);
        return Promise.reject(response)
      }
    })
  }

  const deleteFloorParameters = () => {
    return { floors_attributes: floors.map(f => { return { 'id': f.id, '_destroy': true } }) };
  }

  const setMainUsageParameters = (selectedMainUsageId) => {
    return { building: { ...{ main_usage_id: selectedMainUsageId }, ...deleteFloorParameters() }};
  }

  const setHasOtherUsagesParameters = (value) => {
    return { building: { ...{ has_other_usages: value  }, ...deleteFloorParameters() }};
  }

  const setMainUsage = () => {
    for (const u of usages) u.active = false;
    selectedOtherUsages = [];
    request = sendApiRequest(apiPaths.building(), {
      method: 'PUT',
      headers: {
        'Accept': 'application/html',
        'Content-Type': 'application/json',
        'X-CSRF-Token': getCSRFToken(),
        'X-Requested-With': 'XMLHttpRequest'
      },
      body: JSON.stringify(setMainUsageParameters(selectedMainUsageId))
    }).then((res) => {
      selectedMainUsageId = res.main_usage_id;
      selectedMainUsage = getUsageById(selectedMainUsageId);
      floors = res.floors;
      secondary_usage_ids = res.secondary_usage_ids;
    }, (res) => {
      selectedMainUsageId = null;
      return res.json().then((json) => {
        throw Error(json.error || response.statusText);
      })
    })
  }

  const submitFloorUsages = () => {
    console.log('floor usages', floors);
    request = sendApiRequest(apiPaths.building(), {
      method: 'PUT',
      headers: {
        'Accept': 'application/html',
        'Content-Type': 'application/json',
        'X-CSRF-Token': getCSRFToken(),
        'X-Requested-With': 'XMLHttpRequest'
      },
      body: JSON.stringify({ building: { floors_attributes: floors.map(f => {
        f.floor_usages_attributes = f.floor_usages;
        delete f.floor_usages;
        return f;
      })}
      })
    }).then((res) => {
      return window.location.href = stepPaths.for('precondition');
    }, (res) => {
      return res.json().then((json) => {
        throw Error(json.error || response.statusText);
      })
    })
  }

  const verifyFloorUsages = () => {
    if (floorUsageComponent) {
      let missingUsages = floorUsageComponent.getMissingUsages();
      if (missingUsages.length > 0) return request = Promise.reject({ floor_usages_error: { message: I18n.t('floor_usages_missing_error', { usages: missingUsages.map(u => u[`name_${I18n.locale}`]).join(', ') }) } })
    }
    submitFloorUsages()
  }

  const initFloors = () => {
    var main = [{ 'id': undefined, 'number': 0, floor_usages: [{usage_id: selectedMainUsageId}] }];
    floors = main;
  }

  let selectedMainUsageId = project.building.main_usage_id,
      floors = (project.building ? project.building.floors : null),
      selectedOtherUsages = loadSelectedOtherUsages(),
      request = true,
      selectedMainUsage = null,
      possibleSecondaryUsages = [];

  $: selectedMainUsage = getUsageById(selectedMainUsageId);

  $: console.log('MainUsage', selectedMainUsage, selectedMainUsageId);
  $: possibleSecondaryUsages =  selectedMainUsage ? usages.filter(u =>  selectedMainUsage.secondary_usage_ids.includes(u.id)).map((u) => u.id) : [];

  $: maxFloors = (selectedMainUsage ? selectedMainUsage.max_floors : null);
  $: maxUsagesPerFloors = (selectedMainUsage ? selectedMainUsage.max_usages_per_floor : null);
  $: !floors || floors.length == 0 ? initFloors() : console.log('floors is set:', floors);
  $: selectedUsages = [selectedMainUsage, ...selectedOtherUsages];
  $: console.log('selectedOtherUsages', selectedOtherUsages)
  $: console.log('selectedUsages', selectedUsages)
  $: console.log('primaryUsages', primary_usage_ids, primaryUsages)
  $: console.log('secondaryUsages', possibleSecondaryUsages)

  $: showSecondaryUsagesSelectionInput = possibleSecondaryUsages.length > 0
  $: showFloorUsagesInput = selectedMainUsage
  $: autoselectFloorUsages = showFloorUsagesInput && !selectedMainUsage.show_floor_usage_selection

  $: console.log('selectedUsages.length', selectedUsages.length, 'showFloorUsagesInput', showFloorUsagesInput)

  console.log(project);
</script>

<div id='usage'>
  <h1>{I18n.t(`step.${project.project_kind}.usage`)}</h1>

  {#await request}
    <Spinner></Spinner>
  {:then value}
    <!-- empty on purpose -->
  {:catch error}
    <WarningPanel text={error.message || I18n.t('usages_generic_error')} />
  {/await}

  <form>

    <section class='questionnaire-item'>
      <MainUsageSelectionInput usages={primaryUsages} on:change={setMainUsage} bind:selectedMainUsageId />
      {#if selectedMainUsage && selectedMainUsage[`help_${I18n.currentLocale()}`]}
          <InfoPanel text={selectedMainUsage[`help_${I18n.currentLocale()}`]} />
      {/if}
    </section>

    {#if showSecondaryUsagesSelectionInput}
      <section class='questionnaire-item' transition:fade>
        <OtherUsagesSelectionInput usages={usages} possibleUsageIds={possibleSecondaryUsages} bind:selectedOtherUsages />

        {#if selectedOtherUsages && selectedOtherUsages.length > 0}
            {#each selectedOtherUsages as usage}
              {#if usage[`help_${I18n.currentLocale()}`]}
                <InfoPanel title={usage[`name_${I18n.currentLocale()}`]} text={usage[`help_${I18n.currentLocale()}`]} />
              {/if}
            {/each}
        {/if}

      </section>
    {/if}

    {#if showFloorUsagesInput}
      <section class='questionnaire-item' class:d-none={selectedUsages.length < 1} transition:fade>
        {#await request}<!-- empty on purpose -->{:then value}<!-- empty on purpose -->
        {:catch error}
          {#if error && error.floor_usages_error && error.floor_usages_error.message}<WarningPanel text={error.floor_usages_error.message} />{/if}
        {/await}

        <FloorUsagesInput bind:selectedUsages bind:floors bind:maxFloors bind:maxUsagesPerFloors bind:autoselect={autoselectFloorUsages} bind:this={floorUsageComponent} />
      </section>
    {/if}

    <div class='buttonbar'>
      <a href={''} role='button' on:click|preventDefault={verifyFloorUsages} class='continue-button' class:disabled={!selectedMainUsageId}>{I18n.t('continue')}</a>
      <a href={stepPaths.for('setup')} class='back-button'>{I18n.t('back') }</a>
    </div>
  </form>
</div>