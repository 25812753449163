/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_include_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
import "@webcomponents/webcomponentsjs";
import "core-js/stable";
import "regenerator-runtime/runtime";
import "isomorphic-fetch";

import Rails from '@rails/ujs';
Rails.start();

import '../src/frontend/form.js';

import ClipboardJS from "clipboard";
import 'bootstrap'; // popover for clipboard

import { initAnalytics } from "../src/frontend/analytics.js";
window.initAnalytics = initAnalytics;

import Home from "../src/components/pages/Home.svelte";
import Preconditions from "../src/components/pages/Preconditions.svelte";
import Questionnaire from "../src/components/pages/Questionnaire.svelte";

import QuestionnaireItem from "../src/components/eheureka/QuestionnaireItem.svelte";
import ConstructionTypeInput from '../src/components/eheureka/ConstructionTypeInput.svelte';
import Usage from "../src/components/pages/Usage.svelte";
import EditNav from "../src/components/eheureka/EditNav.svelte";
import Nav from '../src/components/eheureka/Nav.svelte';
import LeaveStepNav from "../src/components/eheureka/LeaveStepNav.svelte";
import StepNav from '../src/components/eheureka/StepNav.svelte';
import ZipInput from '../src/components/eheureka/ZipInput.svelte';
import { linkTo_UnCryptMailto } from '../src/utilities/html.js';
import Wizard from "../src/components/pages/Wizard.svelte";
import Proof from "../src/components/pages/Proof.svelte";
import HelpDialogWithTrigger from "../src/components/eheureka/HelpDialogWithTrigger.svelte";

// Make all images available in Rails views
require.context('../images', true)

import { SveltePony } from "../src/frontend/svelte-pony.js";
const pony = new SveltePony();
pony.saddle({
  ConstructionTypeInput,
  EditNav,
  Home,
  LeaveStepNav,
  Nav,
  Preconditions,
  Questionnaire,
  QuestionnaireItem,
  StepNav,
  Usage,
  ZipInput,
  Wizard,
  Proof,
  HelpDialogWithTrigger
});

// Make all images available in Rails views
require.context('../images', true)

// add clipboard to token
// show tooltip on success
$(function () {
  var clipboard = new ClipboardJS('.clipboard');
  clipboard.on('success', function (e) {
    var cliptip = $('.clipboard').tooltip({
      trigger: 'manual',
      delay: 2,
    }).tooltip('show');
    window.setTimeout(() => { cliptip.tooltip('dispose'); }, 1500);
    e.clearSelection();
  });
  $('#btt a.btn').on('click', function (event) {
    event.preventDefault();
    $('html, body').animate({
      scrollTop: 0
    }, 'slow');
  });

  // enable collapsible sidebar triggers
  $(".sidebar:not(.show)").hover(
    function () {
      $('.sidebar-collapse').collapse('show');
    }, function () {
      $('.sidebar-collapse').collapse('hide');
    }
  );
  $(".sidebar:not(.show)").click(
    function () {
      $('.sidebar-collapse').collapse('toggle');
    }
  );

  // mount svelte components
  pony.mount();
});

window.linkTo_UnCryptMailto = linkTo_UnCryptMailto
