<script>
  import { createEventDispatcher } from "svelte";
  import I18n from "../../utilities/I18n.js";
  import Select from "../generic/Select.svelte";
  import CommentDialog from "./CommentDialog.svelte";
  import CustomDemandDialog from "./CustomDemandDialog.svelte";
  import { proofItems } from "../../stores.js";

  export let item;

  let { id, type, building_id, floor_usage_id, chapter_id, custom } = item,
    displayComment,
    displayCustomDemandDialog,
    emptyChoice = { value: null, label: "---" };

  // add item to index for easy retrieval in Proof component
  $proofItems[id] = item;

  /**
   * reactive item properties
   */
  $: demand = $proofItems[id].demand;

  /**
   * event dispatchers
   */
  const dispatch = createEventDispatcher();

  const commitAnswer = (_event) => {
    dispatch("answer", { id, type, demand_id: demand.id, answer: item.answer });
  };

  const displayHelp = (_event) => {
    dispatch("help", { text: demand.text, help: demand.help });
  };
</script>

<tr class="proof-item" class:skipped={item.answer.skipped}>
  <th class="cell header" scope="row">
    {demand.text || I18n.t("proof.item.text_placeholder")}

    {#if custom}
      <button
        class="btn btn-link btn-custom-item"
        on:click={() => {
          displayCustomDemandDialog = true;
        }}>
        <span class="fas fa-pencil" />
      </button>

      <CustomDemandDialog
        {id}
        demand_id={demand.id}
        text={demand.text}
        bind:visible={displayCustomDemandDialog}
        on:updateItem />
    {:else if demand.help}
      <button class="btn btn-link btn-item-help" on:click={displayHelp} disabled={item.answer.skipped}>
        <span class="fas fa-info-circle" />
      </button>
    {/if}
  </th>
  {#if custom}
    <td class="cell text">
      <textarea
        class="form-control"
        bind:value={item.answer.requirement_value}
        on:change={commitAnswer}
        placeholder="---" />
    </td>
  {:else}
    <td class="cell select select-overflow select-overflow-right">
      {#if demand.requirement_choices && demand.requirement_choices.length > 0}
        <Select
          choices={[emptyChoice, ...demand.requirement_choices]}
          selected={item.answer.requirement_value}
          placeholder="---"
          enabled={!item.answer.skipped}
          hideEmptyState={false}
          on:select={(event) => {
            item.answer.requirement_value = event.detail.value;
          }}
          on:select={commitAnswer} />
      {:else}
        <span class="static">{item.answer.requirement_value || ""}</span>
      {/if}
    </td>
  {/if}
  {#if custom}
    <td class="cell text">
      <textarea
        class="form-control"
        bind:value={item.answer.verification_value}
        on:change={commitAnswer}
        placeholder="---" />
    </td>
  {:else}
    <td class="cell select select-overflow select-overflow-left">
      {#if demand.verification_choices && demand.verification_choices.length > 0}
        <Select
          choices={[emptyChoice, ...demand.verification_choices]}
          selected={item.answer.verification_value}
          placeholder="---"
          enabled={!item.answer.skipped}
          hideEmptyState={false}
          on:select={(event) => {
            item.answer.verification_value = event.detail.value;
          }}
          on:select={commitAnswer} />
      {:else}
        <span class="static">{item.answer.verification_value || ""}</span>
      {/if}
    </td>
  {/if}
  <td class="cell comment">
    <button
      class="btn btn-sm btn-link btn-comment"
      class:with-comment={item.answer.comment}
      title={I18n.t("activerecord.attributes.proof_answer.comment")}
      disabled={item.answer.skipped}
      on:click={() => {
        displayComment = true;
      }}>
      <span class="fas fa-comment-alt" class:fa-comment-alt-lines={item.answer.comment} />
    </button>

    <CommentDialog
      {item}
      bind:visible={displayComment}
      on:commit={(event) => {
        item.answer.comment = event.detail;
      }}
      on:commit={commitAnswer} />
  </td>
  <td class="cell skip">
    <button
      class="btn btn-sm btn-link btn-skip"
      class:skipped={item.answer.skipped}
      title={item.answer.skipped ? I18n.t("proof.item.unskip") : I18n.t("proof.item.skip")}
      on:click={() => {
        item.answer.skipped = !item.answer.skipped;
      }}
      on:click={commitAnswer}>
      <span class="fas" class:fa-times={!item.answer.skipped} class:fa-undo={item.answer.skipped} />
    </button>
  </td>
</tr>

{#if item.error}
  <tr>
    <td colspan="4" class="cell item-error text-danger pb-3">{item.error}</td>
  </tr>
{/if}

<style>
  .proof-item.skipped > th,
  .proof-item.skipped > td:not(.skip) {
    opacity: 0.3;
  }
  .proof-item > .select {
    --selectBorder: none;
    --padding: 0;
  }
  .proof-item > .text > .form-control {
    padding-left: 0;
    padding-right: 0;
    border-bottom: none;
  }
  .cell {
    vertical-align: top;
  }
  .header {
    font-weight: normal;
    padding-top: 12px;
    padding-bottom: 12px;
  }
  .static {
    line-height: 35px;
  }
  .btn-item-help,
  .btn-custom-item {
    margin: -6px -12px;
  }
  .comment,
  .skip {
    padding-top: 8px;
    padding-bottom: 8px;
  }
  .btn-comment,
  .btn-skip {
    color: var(--disabled-grey);
  }
  .btn-comment.with-comment,
  .btn-skip.skipped {
    color: black;
  }
  .item-error {
    border-color: transparent;
  }
  th,
  td {
    hyphens: auto;
  }
</style>
