
export const initAnalytics = (src = "") => {
  console.debug("analytics:init", src)

  // create a new script tag
  const analyticsTag = document.createElement("script");
  analyticsTag.async = true
  analyticsTag.src = src

  // insert the new tag before all other script tags
  const firstScriptTag = document.getElementsByTagName("script")[0];
  firstScriptTag.parentNode.insertBefore(analyticsTag, firstScriptTag);
}
