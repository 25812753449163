<script>
  import I18n from "../../utilities/I18n.js";

  export let kind, url, previewUrl, previewIcon;
</script>

<div class="new-project-button col-12 col-md-4">
  <a class="btn-construction btn-{kind}" href={url}>
    <div class="btn-title">
      {@html I18n.t(`new_welcome.${kind}.title_html`)}
      <span class="fa fa-arrow-right ml-auto" />
    </div>
    <div class="btn-divider" />
    <div class="btn-description">
      {@html I18n.t(`new_welcome.${kind}.description_html`)}
    </div>
  </a>
  <a href={previewUrl} class="pdf-preview text-{kind}" target="_blank">
    <div class="pdf-preview-icon">
      {@html previewIcon || ""}
    </div>
    <p class="pdf-preview-text">
      {@html I18n.t(`new_welcome.${kind}.pdf_preview_html`)}
    </p>
  </a>
</div>
