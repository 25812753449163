<script>
  export let question, 
             enabled = false, 
             answer, 
             errors;
             
  import { createEventDispatcher } from 'svelte';
  import I18n from '../../utilities/I18n.js';


  const dispatch = createEventDispatcher();

  let name = `question_${question.code}`;

  const min = question.configuration ? question.configuration.minimum : undefined;
  const max = question.configuration ? question.configuration.maximum : undefined;

  const handleClick = (event) => {
    dispatch('answer', { id: question.id, code: question.code, answer: answer });
  }
  const handleKeydown = (event) => {
    if(event.keyCode == 13 && isFinite(answer)) dispatch('answer', { id: question.id, code: question.code, answer: answer });
  }
</script>

<style>
  .disabled {
    cursor: pointer;
  }
  .enabled {
    border-bottom: 1px solid var(--primary, #da8b31);
  }
</style>

{#if enabled}
  <div class='form-group'>
    <div class='input-group'>
      <input type='number' class:enabled class:is-invalid={errors && errors.length} class='form-control' min={min} max={max} {name} on:keydown={handleKeydown} bind:value={answer}/>
      <div class='input-group-append'>
        <button disabled={ answer != undefined ? null : 'disabled' } on:click={handleClick} class='enter-button'>{I18n.t('enter')}</button>
      </div>
      {#if errors && errors.length}
        <div class='invalid-feedback'>{errors.join('<br>')}</div>
      {/if}
    </div>
  </div>
{:else}
  <div class='disabled' on:click={() => dispatch('setCurrent', { id: question.id, code: question.code })}>{answer}</div>
{/if}

