<script>
	import { onMount, tick } from 'svelte';
  import I18n from '../../utilities/I18n.js';
  import Select from '../generic/Select.svelte';

  export let selectedUsages = [],
             floors = [],
             maxFloors,
             maxUsagesPerFloors,
             autoselect;

  let floorUsageChoices;
  let selectsEnabled = true;

  const emptyChoice = { value: null, label: '' }
  const createFloorUsage = (usage, floor) => {
    selectsEnabled = false;
    console.log(`adding usage ${usage[`name_${I18n.locale}`]} to floor ${floor.number}`);

    floor.floor_usages = [...floor.floor_usages, { usage_id: usage.value, id: undefined}]
    floors = floors;
    tick().then(() => selectsEnabled = true)
  }
  const deleteFloorUsage = (floorUsage, floor) => {
    console.log(`removing floorUsage ${floorUsage[`name_${I18n.locale}`]} from floor ${floor.number}`);
    if (floorUsage.id) floorUsage._destroy = true;
    else floor.floor_usages.splice(floor.floor_usages.indexOf(floorUsage), 1);
    if (floor.floor_usages.filter(fu => !fu._destroy).length < 1 && selectedUsages.length > 0) createFloorUsage(selectedUsages[0], floor)
    floors = floors;
  }
  const addFloorAbove = () => {
    console.log('adding floor above');
    floors = [{
      number: floors.filter(f => !f._destroy)[0].number+1,
      floor_usages: [(selectedUsages.length > 0 ? { usage_id: selectedUsages[0].id } : null)
    ]}, ...floors]
  }
  const addFloorBelow = () => {
    console.log('adding floor below');
    floors = [...floors, {
      number: floors.filter(f => !f._destroy)[floors.filter(f => !f._destroy).length-1].number-1,
      floor_usages: [(selectedUsages.length > 0 ? { usage_id: selectedUsages[0].id } : null)]
    }]
  }
  const deleteFloor = (floor) => {
    console.log(`removing floor ${floor.number}`);
    if (floor.id) floor._destroy = true;
    else floors.splice(floors.indexOf(floor), 1);
    floors = floors;
  }
  const restoreFloor = (floor) => {
    console.log(`restoring floor ${floor.number}`);
    floor._destroy = false;
    floors = floors;
  }

  const canAddMoreUsagesToFloor = (floor) => {
    console.log('canAddMoreUsagesToFloor', selectedUsages);
    return selectedUsages.length > 1 && (!maxUsagesPerFloors || floor.floor_usages && floor.floor_usages.filter(u => !u._destroy).length < maxUsagesPerFloors) && selectedUsages.length > floor.floor_usages.filter(u => !u._destroy).length
  }

  const cleanupFloorUsages = (selectedUsages) => {
    for (const f of floors) {
      for (const fu of f.floor_usages) {
        if (!selectedUsages.map(u => u.id).includes(fu.usage_id)) deleteFloorUsage(fu, f)
      }
    }
  }

  export const getMissingUsages = () => {
    let distributedUsageIds = floors.map(f => f.floor_usages.filter(fu => !fu._destroy).map(fu => fu.usage_id)).flat();
    console.log('distributed usage ids', distributedUsageIds);
    let missingUsages = [];
    for (const u of selectedUsages) {
      if (!distributedUsageIds.includes(u.id)) missingUsages.push(u)
    }
    if (missingUsages.length > 0) {
      if (autoselect) {
        console.log('autoselect is on, adding missing usages to ground floor', missingUsages);
        for (const usage of missingUsages) floors[0].floor_usages.push({ usage_id: usage.id })
        return []
      } else {
        console.log('missing usages', missingUsages);
      }
    }
    return missingUsages
  }

  const floorNumber = (number) => {
    if (number == 0) return I18n.t('activerecord.attributes.project.ground_floor_abbr')
    else if (number > 0) return I18n.t('activerecord.attributes.project.upper_floor_abbr', { count: number })
    else if (number < 0) return I18n.t('activerecord.attributes.project.basement_floor_abbr', { count: Math.abs(number) })   
  }

  $: floorCount = floors.filter(f => !f._destroy).length;
  $: floorUsageChoices = floors.reduce((acc, cur) => { acc[cur.number] = selectedUsages.filter((su) => -1 == cur.floor_usages.filter(fu => !fu._destroy).map((fu) => fu.usage_id).indexOf(su.id)); return acc }, {})
  $: cleanupFloorUsages(selectedUsages);
  $: console.log('floorUsageChoices', floorUsageChoices)

  onMount(() => {
    if (floors.length < 1) floors = [{ number: 0, floor_usages: [(selectedUsages.length > 0 ? { usage_id: selectedUsages[0].id } : null)] }]
  })
</script>

<style>
  .floor-usages-wrapper {
    background-color: lightgrey;
    padding: 5px;
  }
  .floor-usages-wrapper > * {
    margin: 5px;
  }
  .floor-wrapper {
    background-color: white;
  }
  .floor-number, .floor-usage, .remove-floor {
	  font-family: 'Frutiger Bold';
    vertical-align: top;
  }
  .floor-usages {
    margin: 0 -8px;  /* compensate white-spaces between inline elements */
  }
  .floor-number, .remove-floor {
    padding: 0.75rem;
  }
  .list-group-item {
    border: none;
    border-top: 1px solid rgba(75, 75, 75, 0.125);
    padding-left: 16px;
    padding-right: 16px;
  }
  .list-group-item:first-of-type {
    border: none;
  }
  .list-group-item.selector {
    padding-left: 0;
    padding-right: 0;
    padding-top: 0.3rem;
    border: none;
  }
  .btn-remove {
    float: right;
	  padding: 0;
    background-color: transparent;
    border: 0;
    font-weight: 700;
    line-height: 1;
  }
  .floor-wrapper.markedfordeletion {
    display: none;
  }

</style>

<section class="questionnaire-item" class:d-none={autoselect}>

<span class='question'>{selectedUsages.length > 1 ? I18n.t('activerecord.attributes.project.floor_usages') : I18n.t('activerecord.attributes.project.floor_count')}</span>
<div class='answers'>
  <div class="bool-question enabled">
    <div class="floor-usages-wrapper">
      <button type="button" class="btn btn-sm btn-secondary" on:click={addFloorAbove} disabled={maxFloors && floorCount >= maxFloors}><span class="fa fa-plus"></span> {I18n.t('activerecord.attributes.project.add_floor_above')}</button>
      <small>{(maxFloors && floorCount >= maxFloors ? I18n.t('floor_limit_reached') : '')}</small>

      {#each floors as f}
        <div class="floor-wrapper" class:markedfordeletion={f._destroy}>
          <div class="floor-number d-inline-block col-2 col-sm-1">{@html floorNumber(f.number)}</div>
          <div class="floor-usages d-inline-block col-9 col-sm-10">
            <ul class="list-group">
              {#if f.floor_usages}
                {#each f.floor_usages as fu}
                  {#if selectedUsages.find(u => u.id === fu.usage_id) && !fu._destroy}
                  <li class="list-group-item floor-usage">
                    <span>{selectedUsages.find(u => u.id === fu.usage_id)[`name_${I18n.locale}`]}</span>
                    {#if f.floor_usages && f.floor_usages.filter(u => !u._destroy).length > 1}<button type="button" class="btn-remove" on:click={() => deleteFloorUsage(fu, f)} title="{I18n.t('activerecord.attributes.project.delete_floor_usage')}"><span aria-hidden="true">&times;</span></button>{/if}
                  </li>
                  {/if}
                {/each}
              {/if}
              {#if selectsEnabled && selectedUsages.length > 1 && (!maxUsagesPerFloors || f.floor_usages && f.floor_usages.filter(u => !u._destroy).length < maxUsagesPerFloors) && selectedUsages.length > f.floor_usages.filter(u => !u._destroy).length}
                <li class="list-group-item selector">
                  <Select choices={floorUsageChoices[f.number]} enabled on:select={(e) => { createFloorUsage(e.detail, f) }} placeholder='{I18n.t('activerecord.attributes.project.select_additional_usage')}' />
                </li>
              {/if}

            </ul>
          </div>
          {#if ((f.number > 0 || f.number < 0) && (floors.filter(f => !f._destroy).indexOf(f) === 0 || floors.filter(f => !f._destroy).indexOf(f) === floors.filter(f => !f._destroy).length-1))}
            <div class="remove-floor d-inline-block col-1"><button type="button" class="btn-remove" on:click={() => deleteFloor(f)} title="{I18n.t('activerecord.attributes.project.delete_floor')}"><span class="fa fa-trash" aria-hidden="true" /></button></div>
          {/if}
        </div>
      {/each}

      <button type="button" class="btn btn-sm btn-secondary" on:click={addFloorBelow} disabled={maxFloors && floorCount >= maxFloors}><span class="fa fa-plus"></span> {I18n.t('activerecord.attributes.project.add_floor_below')}</button>
      <small>{(maxFloors && floorCount >= maxFloors ? I18n.t('floor_limit_reached') : '')}</small>

    </div>

  </div>
</div>

</section>