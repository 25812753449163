<svelte:options immutable/>

<script>
  import { wizardSession as state } from '../../stores.js';

  import I18n from '../../utilities/I18n.js';
  import { t } from '../../utilities/general.js';

  import QuestionnaireItem from "../eheureka/QuestionnaireItem.svelte";
  import WizardRulesetItem from "../wizards/WizardRulesetItem.svelte";
  import Spinner from "../generic/Spinner.svelte";
  import WarningPanel from "../eheureka/WarningPanel.svelte";

  import { getCSRFToken } from '../../utilities/html.js'
  import { ApiPaths } from "../../utilities/paths.js";

  import { tick, onMount } from 'svelte';

  function getwizardSession() {
    console.log('getwizardSession()', $state);

    return fetch(paths.wizardSession(), {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      }
    }).then((response) => {
      if(!response.ok) {
        console.error('getwizardSession Response not ok', response)
        throw Error(response.statusText);
      }
      console.log('getwizardSession response', response)
      return response;
    }).then((response) => {
      return response.json().then((json) => {
          console.log('getwizardSession result: ', json);
          return json;
        });
    }).catch((error) => {
      console.log('Error in getwizardSessionPromise', error);
      return Promise.reject(error);
    })
  }

  function postAnswer(questionId, value) {
    console.log('postAnswer', $state, questionId, value);
    return fetch(paths.answerQuestion(questionId), {
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-CSRF-Token': getCSRFToken(),
        'X-Requested-With': 'XMLHttpRequest'
      },
      credentials: 'same-origin',
      body: JSON.stringify({answer: {
        value: value
      } })
    }).then((response) => {
      if (response.ok) {
        return response.json().then((json) => {
          return {
            answer: (json.answer ? json.answer.value : json.answer),
            errors: json.errors
          }
        })
      } else {
        console.error('response is not ok', response);

        return response.json().then((json) => {
          throw Error(json.error || response.statusText);
        })
      }
    }).catch((error) => {
      console.log('Error in postAnswerPromise', error);
      return Promise.reject(error);
    })
  }

  const updateState = ([{ answer, errors}, wizardSession]) => {
    console.log('handleAnswer', $state, answer, errors, wizardSession)

    $state.current_question.answer = answer;
    $state.current_question.errors = errors;

    if(!errors || errors.length == 0) {
      $state.questionnaire = [...wizardSession.questionnaire]
      $state.current_question = wizardSession.current_question;
      $state.current_question.answer = $state.current_question.answer;
      $state.remaining_question_count = wizardSession.remaining_question_count;
      $state.rule_set = wizardSession.rule_set;
    }

    $state = { ...$state }

    tick().then(() => scrollToCurrentItem());

    console.log('handleAnswerPromise done', $state);
  }

  function handleAnswer(event) {
    const postAnswerPromise = postAnswer(event.detail.id, event.detail.answer, event.detail.buildingId, event.detail.floorUsageId);
    const newQuestionPromise = postAnswerPromise.then(getwizardSession);

    promise = Promise.all([postAnswerPromise, newQuestionPromise]).then(updateState)
  }

  function handleSetCurrent(event) {
    promise = new Promise((resolve, reject) => {
      console.log('Questionnaire.handleSetCurrent', event, wizardSession.questionnaire, wizardSession.current_question);

      const id = event.detail.id;
      let newCurrentIdx = 0;
      let idFound = false;
      for(let item of $state.questionnaire) {
        if(item.question.id == id) {
          idFound = true;
          break;
        }
        newCurrentIdx++;
      }

      if(!idFound) {
        console.error('invalid set Current', id);
        reject(`invalid id ${id}`);
      }
      console.log('jusqu\'ici tout va bien');

      const newCurrent = $state.questionnaire[newCurrentIdx];
      console.log('newCurrent', newCurrent);
      const newRemainingCount = $state.remaining_question_count + ($state.questionnaire.length - newCurrentIdx)
      console.log('newRemainingCount', newRemainingCount);
      const newQuestionnaire = $state.questionnaire.splice(0, newCurrentIdx) ;
      console.log('newQuestionnaire', newQuestionnaire);

      $state = {
          ...$state,
          questionnaire: newQuestionnaire,
          current_question: newCurrent,
          remaining_question_count: newRemainingCount
        }

      if(event.detail.answer != undefined) {
        const postAnswerPromise = postAnswer(event.detail.id, event.detail.answer, event.detail.buildingId, event.detail.floorUsageId);
        const newQuestionPromise = postAnswerPromise.then(getwizardSession);

        resolve(Promise.all([postAnswerPromise, newQuestionPromise]).then(updateState));
      } else {
        resolve();
      }
    })
  }

  function scrollToCurrentItem() {
    let currentItem = finished ? document.querySelector('#wizard-ruleset') : document.querySelector('.questionnaire-item:last-child');

    if (currentItem.getBoundingClientRect().bottom > window.innerHeight - 69) {
      currentItem.scrollIntoView({ left: 0, block: 'start', behavior: 'smooth' });
    }
    addEventListener('scroll', (e) => {
      if (currentItem.getBoundingClientRect().top < 0) {
        currentItem.scrollIntoView({ left: 0, block: 'start', behavior: 'smooth' });
      }
    })
  }

  export let apiBaseUrl = "",
             wizardSession;

  const paths = new ApiPaths(apiBaseUrl);

  let promise, questionnaire, currentQuestion;

  // Prevent spinner on load
  promise = true; //Promise.resolve(true);

  $state = wizardSession;

  console.log('init Questionnaire');
  console.log('onInit: wizardSession', $state);
  console.log('onInit: currentQuestion', currentQuestion);
  console.log('onInit: questionnaire', questionnaire);
  console.log('init Questionnaire done');

  $: finished = $state.current_question === true;

  $: {
    console.log('update Questionnaire');
    console.log('onUpdate: wizardSession', $state);
    console.log('onUpdate: currentQuestion', $state.current_question);
    console.log('onUpdate: questionnaire', $state.questionnaire);
    console.log('update Questionnaire done');
  }

  onMount(async () => { await tick(); scrollToCurrentItem()})
</script>

<style>
  .heading {
    white-space: nowrap;
    margin-bottom: 4rem;
    padding-top: 2rem;
  }
  .heading > * {
    white-space: normal;
    vertical-align: bottom;
    padding: 0;
  }
  .heading h1 {
    margin-bottom: 0;
  }
  .error {
    margin-top: -2rem;
    margin-bottom: 2rem;
  }
</style>

<div id="wizard-heading" class="heading">
  <h1>{t($state.wizard, 'name')}</h1>
</div>

{#await promise}
  <Spinner></Spinner>
{:then id}
  <!-- empty on purpose -->
{:catch error}
  <div class="error">
    <WarningPanel text={I18n.t('wizards.frontend.general_error')} />
  </div>
{/await}

<div class='questions mb-5'>
  {#each [...$state.questionnaire, $state.current_question] as item, index (item === true ? '' : `${item.usage_id}_${item.question.code}_${item.answer}`)}
    {#if item === true}
      <!-- empty on purpose -->
    {:else}
      <QuestionnaireItem
        enabled={typeof $state.current_question.question != 'undefined' && typeof item.question != 'undefined' && $state.current_question.question.code == item.question.code}
        {item}
        answer={item.answer}
        errors={item.errors}
        on:answer={handleAnswer}
        on:setCurrent={(e) => $state.current_question !== true && $state.current_question.question.code == item.question.code ? false : handleSetCurrent(e)} />
    {/if}
  {/each}
</div>

{#if finished}
  <div id="wizard-ruleset" class='questions wizard-ruleset mb-5'>
    {#each $state.rule_set as item, index}
      <WizardRulesetItem {item} />
    {/each}
</div>
{/if}
