<script>
  import I18n from '../../utilities/I18n.js';
  import UsageSelectionOption from './UsageSelectionOption.svelte';
  import Select from '../generic/Select.svelte';

  export let usages = [], 
             selectedOtherUsages = [], 
             possibleUsageIds = [];

  const updateSelection = (e) => {
    selectedOtherUsages = usages.filter(s => s.active === true);
  }
  $: possibleUsages = usages.filter(u => possibleUsageIds.includes(u.id))
  $: console.log('possibleUsageIds', possibleUsageIds, 'usages', usages)
</script>


<span class='question'>{I18n.t('activerecord.attributes.project.other_usages')}</span>
<p class='question-help'>{I18n.t('activerecord.attributes.project.other_usages_help')}</p>
<div class='answers'>
  <div class="bool-question enabled">
  {#each possibleUsages as u}
    <UsageSelectionOption type="checkbox" label={u[`name_${I18n.locale}`]} bind:checked={u.active} icon={u.icon} code={u.code} mainUsage={false} on:change={updateSelection} />
  {/each}
  </div>
</div>
