<script>
  import { createEventDispatcher } from 'svelte';
  import I18n from '../../utilities/I18n.js';
  import UsageSelectionOption from './UsageSelectionOption.svelte';
  import Select from '../generic/Select.svelte';

  export let usages, 
             selectedMainUsageId;
             
</script>


<div class='answers'>
  <div class="bool-question enabled">
  {#each usages as u}
    <UsageSelectionOption type="radio" label={u[`name_${I18n.locale}`]} bind:checked={u.active} bind:group={selectedMainUsageId} value={u.id} icon={u.icon} code={u.code} mainUsage={true} on:change />
  {/each}
  </div>
</div>

