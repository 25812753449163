<script>
  import I18n from '../../utilities/I18n.js';
  import WarningPanel from './WarningPanel.svelte';
  import Spinner from "../generic/Spinner.svelte";

  export let value, 
             checkZipUrl;
             
  let zipOk = {result: true}, promise, zipNok = false;

  async function handleChange() {
    if(value) {
      value = Math.abs(value);
      let url = `${checkZipUrl}`;
      const response = await fetch(url.replace('replace', value), { method: 'GET', headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        }});
      if(response.ok) {
        zipOk = await response.json();
      }
    }
  }

  async function handleKeyPress(event) {
    if(event.keyCode == 13){
      handleChange();
      event.preventDefault();
    }
  }

  $: zipNok = !zipOk.result;

</script>

<div class="form-group integer required project_zip">
  <label class="integer required" for="project_zip">{I18n.t('activerecord.attributes.project.zip')}
    <abbr title={I18n.t('simple_form.required.text')}>{I18n.t('simple_form.required.mark')}</abbr>
  </label>
  <input class="form-control numeric integer required"
    bind:value
    required='required'
    type="number"
    step="1"
    name="project[zip]"
    id="project_zip"
    maxlength={4}
    min="0"
    on:blur={handleChange}
    on:keypress={handleKeyPress}
  >
</div>
{#if zipNok}
  <div class='mb-5'>
    <WarningPanel>
      {@html I18n.t('zip_not_bern')}
    </WarningPanel>
  </div>
{/if}


