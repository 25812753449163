<!-- <script contey  -->

<script>
  import Dialog from "../generic/Dialog.svelte";

  let visible = false,
    text = "",
    help = "";

  export const displayHelp = (event) => {
    ({ text, help } = event.detail);
    visible = true;
  };
</script>

<Dialog bind:visible>
  <h3>{text}</h3>
  <p>{@html help}</p>
</Dialog>
