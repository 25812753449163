<script>
  import Select from 'svelte-select/src/Select.svelte';
  import SelectItem from './SelectItem.svelte';
  import I18n from '../../utilities/I18n.js';
  import { t } from '../../utilities/general.js';
  import { handleFormInputChange } from '../../frontend/form';
  import { createEventDispatcher } from 'svelte';



  export let enabled, 
             choices, 
             selected, 
             name, 
             placeholder = I18n.t('helpers.select.prompt'), 
             id, 
             required = false;

  let inputAttributes, inputElement;
  if( name ) {
    inputAttributes = { name: name };
  }

  const dispatch = createEventDispatcher();
  const emitChange = (_event) => { dispatch('change') }

  $: value = selected ? selected.value : '';
</script>

<style>
  .select {
    --border: none;
    --inputFontSize: 16px;
    --disabledColor: #333;
    --disabledBackground: white;
    --disabledBorder: none;
    --itemIsActiveBG: var(--primary, #da8b31);
    --multiItemActiveBG: var(--primary, #da8b31);
    --multiClearHoverFill: var(--primary, #da8b31);
    --multiItemBorderRadius: 0;
    --multiItemMargin: 3px 6px 3px 0;
    --itemHoverBG: #efefef;
    --borderRadius: 0;
    --itemFirstBorderRadius: 0;
    --itemIsActiveColor: white;
    --listShadow: 1px 1px 10px #ccc;
    --listMaxHeight: 33vh;
    --height: 35px;
    --indicatorTop: 5px;

    display: flex;
  }

  .select.enabled {
    border-bottom: 1px solid var(--primary, #da8b31);
  }

  .enter-button {
    z-index: 1;
  }
</style>


<div class='select' class:enabled>
  <Select
    {placeholder}
    isDisabled={!enabled}
    items={choices}
    bind:value={selected}
    on:select
    on:select={() => handleFormInputChange({target: inputElement})}
    hideEmptyState
    isClearable={false}
    isSearchable={false}
    isMulti={true}
    Item={SelectItem}
    containerStyles={'flex-grow: 1'} />

    <button disabled={!selected} on:click={emitChange} class='enter-button'>{I18n.t('enter')}</button>
</div>
{#if name}
  <input bind:this={inputElement} class:required type='hidden' name={name} bind:value={value} {id}/>
{/if}


