<script>
  export let question, 
             enabled = false;
             
  import { createEventDispatcher } from 'svelte';
  import I18n from '../../utilities/I18n.js';
  import { t } from '../../utilities/general.js';

  import WarningPanel from '../eheureka/WarningPanel.svelte';

  import Dialog from '../generic/Dialog.svelte';

  const dispatch = createEventDispatcher();

  let name = `question_${question.code}`;

  const handleClick = (event) => {
    dispatch('answer', { id: question.id, code: question.code, answer: true });
  }

  const isExit = question.configuration && question.configuration.is_exit

  const title = t(question, 'text');
  const text = t(question, 'help');
  const buttonText = question.configuration && t(question.configuration, 'button_text') ? t(question.configuration, 'button_text') : I18n.t('ok');

</script>

<WarningPanel {title} {text}>
  {#if !isExit && enabled}
    <div class='buttonbar'>
      <button class='alert-button' on:click={handleClick}>{buttonText}</button>
    </div>
  {/if}
</WarningPanel>