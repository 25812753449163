
export class SveltePony {
  CLASS_ATTRIBUTE_NAME = 'data-svelte-component'
  PROPS_ATTRIBUTE_NAME = 'data-svelte-props'

  constructor() {
    this.registeredComponents = {};
  }

  saddle(components) {
    const collisions = Object.keys(this.registeredComponents).filter(c => Object.keys(components).includes(c));

    if (collisions.length > 0) {
      console.error(`The following components are registered (at least) twice: ${collisions}`);
    }

    this.registeredComponents = { ...this.registeredComponents, ...components };
  }

  mount() {
    const toMount = document.querySelectorAll(`[${this.CLASS_ATTRIBUTE_NAME}]`);

    for (const node of toMount) {
      const className = node.getAttribute(this.CLASS_ATTRIBUTE_NAME);
      const component = this.registeredComponents[className];

      if (component) {
        if (node.innerHTML.length === 0) this.renderComponent(node, component)
      } else {
        console.error(`Component ${className} has not been registered`)
      }
    }
  }

  renderComponent(node, Component) {
    const propsString = node.getAttribute(this.PROPS_ATTRIBUTE_NAME);
    const props = propsString && JSON.parse(propsString);

    new Component({
      target: node,
      props
    })
  }
}
