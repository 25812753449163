<script>
  import { fade } from 'svelte';
  import { createEventDispatcher } from 'svelte';
  import { t } from '../../utilities/general.js';
  import Select from '../generic/Select.svelte';
  import RadioGroup from '../generic/RadioGroup.svelte';

  export let question, 
             enabled = false, 
             answer,
             id;

  const dispatch = createEventDispatcher();

  const handleChange = (event) => {
    dispatch('answer', { id: question.id, code: question.code, answer: event.detail.value });
  }

  const handleDisabledClick = (event) => {
    console.log('handleDisabledClick', event, enabled);
    if(!enabled) {
      dispatch('setCurrent', { id: question.id, code: question.code });
    }
  }

  let choices, selected, displayAs;

  choices = question.configuration.choices.map((c) => {
    return { value: c.value, label: t(c, 'label'), help: t(c, 'help'), iconPath: c.icon };
  });

  displayAs = question.configuration.display_as;

  const transAnswer = (value) => {
    for(let i=0; i < choices.length; i++) {
      if(choices[i].value == value)
        return choices[i].label
    }
    return '';
  }

  console.log(answer, 'is answer');
</script>

{#if enabled}
  {#if displayAs == 'checkbox'}
    <RadioGroup {enabled} {choices} bind:selected={answer} {id} on:select={handleChange} />
  {:else}
    <Select {enabled} {choices} bind:selected={answer} {id} on:select={handleChange} />
  {/if}
{:else}
  <div class:pointer={!enabled} on:click={handleDisabledClick}>
    {answer ? transAnswer(answer) : ''}
  </div>
{/if}
