<script>
  import { substeps, activeSubstep, activeUsage } from "../../stores.js";
  import { t } from "../../utilities/general.js";

  import I18n from "../../utilities/I18n.js";
  import ProofItem from "../proof/ProofItem.svelte";
  import { project as state } from "../../stores.js";
  import ProofChapter from "./ProofChapter.svelte";

  export let section = "",
    usage,
    sectionChapters = [],
    scrollToTop,
    finished;

  const chapters = $state.proof.chapters;

  const substep = {
    label: (section && I18n.t(`proof.section.${section}`)) || t(usage, "name"),
    url: `#${section || usage.code}`,
    clickable: true,
    onclick: setCurrent,
    usage,
  };

  function setCurrent() {
    $activeSubstep = substep;
    $activeUsage = usage;
    scrollToTop();
  }

  $substeps = [...$substeps, substep];
</script>

{#if $activeSubstep == substep}
  <section class="mb-5">
    {#each Object.entries(sectionChapters) as [chapterCode, subchapters]}
      <section class="mb-3">
        <h3 class="mb-3">
          {t(chapters[chapterCode], "title")}
        </h3>

        {#each Object.entries(subchapters) as [subchapterCode, items]}
          <ProofChapter code={subchapterCode} {items} on:help on:answer on:updateItem bind:finished />
        {/each}
      </section>
    {/each}
  </section>
{/if}
