<!-- <script contey  -->

<script>
  import Dialog from "../generic/Dialog.svelte";

  export let visible = false,
    text = "",
    help = "";
</script>

<button type="button" class="btn btn-sm btn-link" on:click={() => (visible = true)}>
  <span class="fas fa-info-circle" />
</button>

<Dialog bind:visible>
  <h3>{text}</h3>
  <p>{@html help}</p>
</Dialog>
