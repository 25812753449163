<script>
  import I18n from '../../utilities/I18n.js'
  import Precondition from '../eheureka/Precondition.svelte';

  import { StepPaths } from "../../utilities/paths.js";

  import Dialog from '../generic/Dialog.svelte';
  import WarningPanel from '../eheureka/WarningPanel.svelte';

  import { progress } from '../../stores.js';

  import { getCSRFToken } from '../../utilities/html.js';

  export let preconditions = [], 
             baseUrl = '', 
             values,
             project;

  const paths = new StepPaths(baseUrl);

  let showBackWarning = false;

  for(let p of preconditions) {
    if(values[p.id] == undefined) values[p.id] = null;
  }

  const handleAnswer = (event) => {
    const { id, answer } = event.detail;
    values[id] = answer;
  }

  const reset = () => {
    for(let k in values) {
      if(values[k]) {
        values[k] = null;
      }
    }
  }

  const confirmBack = () => {
    window.location.href = paths.for('usage');
  }

  $: notPossible = Object.values(values).find(v => v == true);
  $: canContinue = Object.values(values).reduce((r, v) => r && v == false, true);
  $: numberOfAnswered = Object.values(values).reduce((r, v) => {
      if(v == false) {
        r++;
      }
      return r;
    }, 0);
  $:  $progress = numberOfAnswered / preconditions.length

  // console.log(values);
</script>

{#if showBackWarning}
  <Dialog bind:visible={showBackWarning}>
    <p>{I18n.t('confirm.back')}</p>
    <div class='buttonbar'>
      <button class='continue-button' on:click={confirmBack}>{I18n.t('ok')}</button>
      <button class='back-button'on:click={() => showBackWarning = false}>{I18n.t('stay_on_page')}</button>
    </div>
  </Dialog>
{/if}

<h1>{I18n.t(`step.${project.project_kind}.precondition`)}</h1>

<form method='post' action={paths.for('precondition')} id='preconditions-form'>
  <input type="hidden" name="authenticity_token" value={getCSRFToken()}>
  <div class='questions'>
    {#each preconditions as precondition (precondition.id)}
      <Precondition {precondition} value={values[precondition.id]} on:answer={handleAnswer}>
        {#if values[precondition.id] == true}
          <WarningPanel title={I18n.t('too_complex.title')} text={I18n.t('too_complex.text')} />
        {/if}
      </Precondition>
    {/each}
  </div>
</form>

<div class='buttonbar'>
  <button class:disabled={ !canContinue } disabled={!canContinue} class='continue-button' role='button' on:click={() => document.getElementById("preconditions-form").submit()}>
    {I18n.t('continue')}
  </button>
  <button class='back-button' role='button' on:click|preventDefault={() => showBackWarning = true}>
    {I18n.t('back')}
  </button>
</div>


