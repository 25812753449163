<script>
  import { createEventDispatcher, onMount } from "svelte";
  import I18n from "../../utilities/I18n";
  import Dialog from "../generic/Dialog.svelte";

  export let item,
    visible = false;

  let comment, dirty;

  const dispatch = createEventDispatcher();

  const commit = (_event) => {
    dispatch("commit", comment);
  };

  const commitAndClose = () => {
    if (dirty) commit();

    visible = false;
  };

  onMount(() => {
    dirty = false;
  });
</script>

<Dialog bind:visible>
  <h3>{item.demand.text}: {I18n.t("activerecord.attributes.proof_answer.comment")}</h3>
  <span
    class="textarea form-control mt-2"
    contenteditable="true"
    bind:textContent={comment}
    data-placeholder={I18n.t("activerecord.attributes.proof_answer.comment")}
    on:input={() => (dirty = true)}>
    {item.answer.comment || ""}
  </span>
  <div class="buttonbar">
    <button class="btn btn-primary" on:click={commitAndClose}>{I18n.t("ok")}</button>
  </div>
</Dialog>

<style>
  .textarea {
    display: inline-block;
    padding: 0;
    height: auto;
    min-height: calc(3.5em);
    cursor: text;
    white-space: pre-wrap;
  }
  .textarea:empty:not(:focus):before {
    content: attr(data-placeholder);
    color: var(--secondary);
  }
</style>
