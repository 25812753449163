<script>
  import I18n from "../../utilities/I18n.js";
  import WarningPanel from "../eheureka/WarningPanel.svelte";
  import NewProjectButton from "../eheureka/NewProjectButton.svelte";

  export let baseUrl,
    projectKinds = [],
    projectPaths = {},
    previewPaths = {},
    previewIcon,
    userAlerts = [];

  let enteredCode;

  $: formAction = enteredCode ? `${baseUrl}/${enteredCode}/edit` : "";
  $: buttonDisabled = !enteredCode || enteredCode.length != 19;
</script>

<h1>{I18n.t("new_welcome.title")}</h1>

<div class="home-user-alert">
  {#each userAlerts as alert}
    <WarningPanel text={alert.localize} />
  {/each}
</div>

<div class="welcome-text">{@html I18n.t("new_welcome.introduction_html")}</div>

<div class="mt-4">
  <section class="welcome-section new-project">
    <h2>{I18n.t("new_welcome.new_project")}</h2>
    <div class="new-project-buttons row">
      {#each projectKinds as kind}
        <NewProjectButton {kind} url={projectPaths[kind]} previewUrl={previewPaths[kind]} {previewIcon} />
      {/each}
    </div>
  </section>
</div>

<div class="row mt-5">
  <section class="welcome-section existing-project col-md-8">
    <h2>{I18n.t("new_welcome.existing_project")}</h2>

    <div>
      <p>{I18n.t("open_project_dialog.text")}</p>
      <form action={formAction} method="get" class="open-project-form">
        <input class="form-control" bind:value={enteredCode} maxlength="19" placeholder="XXXX-XXXX-XXXX-XXXX" />
        <input
          type="submit"
          disabled={buttonDisabled}
          role="button"
          class="open-project-button ml-5"
          value={I18n.t("open")} />
      </form>
    </div>
  </section>
</div>
