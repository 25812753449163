<script>
  import { createEventDispatcher } from 'svelte';

  export let enabled,
             choices, 
             selected,
             id;

  const dispatch = createEventDispatcher();

  for(let c in choices) {
    if(`${choices[c].value}` == `${selected}`) {
      selected = choices[c];
    }
  }

  let selectedValue;
  $: console.info('selectedValue', selectedValue);
  $: console.info('value', value);

  const handleChange = (event) => {
    dispatch('select', { value: selectedValue });
  }

  $: value = selected ? selected.value : '';
</script>

<style>
  .radio_buttons {
    display: flex;
    flex-wrap: wrap;
  }

  .custom-control-label, .question-help {
    padding-right: 1.75rem;
    padding-bottom: 0.75rem;
  }
</style>

<div class="radio-group" class:enabled>
  <div class="radio_buttons">
    {#each choices as choice, index}
      <div class="custom-control">
        <input class="custom-control-input radio_buttons" 
               type="radio"
               value={choice.value}
               id="{id}_{index}"
               name={id}
               bind:group={selectedValue}
               on:change={handleChange}>
        <label class="custom-control-label collection_radio_buttons" for="{id}_{index}">{choice.label}</label>
        {#if choice.help}
          <p>
            <small class="question-help">{@html choice.help}</small>
          </p>
        {/if}
      </div>
    {/each}
  </div>
</div>
