<script>
  import { createEventDispatcher } from 'svelte';
  import I18n from '../../utilities/I18n.js';
  import WarningPanel from './WarningPanel.svelte';

  export let value;
  
  const checked = 'bool-checked', unchecked = 'bool-unchecked';
  const dispatch = createEventDispatcher();

  const triggerChange = () => dispatch('change', { value })
</script>

<span class='question'>{I18n.t('activerecord.attributes.project.other_usages')}</span>
<div class='answers'>
  <div class="bool-question enabled">
    <span class='control' on:click|stopPropagation={() => value = true} on:click={triggerChange}>
      <span id={'project_other_usage_1'} class={ value == true ? checked : unchecked} />
      <label class='ml-2' >{I18n.t('yes')}</label>
    </span>
    <span class='control ml-4' on:click|stopPropagation={() => value = false} on:click={triggerChange}>
      <span id={'project_other_usage_0'} class={ value == false ? checked : unchecked} />
      <label class='ml-2' >{I18n.t('no')}</label>
    </span>
  </div>
</div>
