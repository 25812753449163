<script>
  import { fade } from 'svelte';
  import { createEventDispatcher } from 'svelte';
  import { t } from '../../utilities/general.js';
  import Multiselect from '../generic/Multiselect.svelte';
  import CheckboxGroup from '../generic/CheckboxGroup.svelte';
  import I18n from '../../utilities/I18n.js';

  export let question, 
             enabled = false, 
             answer,
             id;

  const dispatch = createEventDispatcher();

  const handleChange = (_event) => {
    dispatch('answer', { id: question.id, code: question.code, answer });
  }

  const handleDisabledClick = (event) => {
    console.log('handleDisabledClick', event, enabled);
    if(!enabled) {
      dispatch('setCurrent', { id: question.id, code: question.code });
    }
  }

  let choices, selected, displayAs;

  choices = question.configuration.choices.map((c) => {
    return { value: c.value, label: t(c, 'label'), help: t(c, 'help'), iconPath: c.icon };
  });

  displayAs = question.configuration.display_as;

  // map answer values to corresponding choice objects
  if (answer) {
    for (const c of choices) {
      let i = answer.indexOf(c.value);
      if (i >= 0) answer[i] = c;
    }
  }

  const transAnswer = (answer) => {
    let answers = []
    for(const c of choices) {
      if(answer.map(a => a.value || a).includes(c.value))
        answers.push(c.label)
    }
    return answers.join(', ');
  }

  console.log(answer, 'is answer');
</script>

{#if enabled}
  {#if displayAs == 'checkbox'}
    <CheckboxGroup {enabled} {choices} bind:selected={answer} {id} on:change={handleChange} />
  {:else}
    <Multiselect {enabled} {choices} bind:selected={answer} {id} on:change={handleChange} />
  {/if}
{:else}
  <div class:pointer={!enabled} on:click={handleDisabledClick}>
    {answer ? transAnswer(answer) : ''}
  </div>
{/if}
