<script>
  import I18n from '../../utilities/I18n.js';

  export let title, 
             text;
</script>
<style>
  .info-panel {
    display: flex;
    align-items: stretch;
  }
  .col1 {
    padding-right: 15px;
  }
</style>
<div class='info-panel'>
  <div class='col1'>
    <span class="fas fa-info-circle"></span>
  </div>
  <div class='col2'>
    {#if title}
      <div class='title'>
        {@html title}
      </div>
    {/if}
    {#if text}
      <div class='text'>
        {@html text}
      </div>
    {/if}
    <slot />
  </div>
</div>