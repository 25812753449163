<script>
  export let question, 
             enabled = false, 
             answer = '', 
             errors;
             
  import { createEventDispatcher } from 'svelte';
  import I18n from '../../utilities/I18n.js';

  const dispatch = createEventDispatcher();

  let name = `question_${question.code}`;

  const min = question.configuration ? question.configuration.minimum : undefined;
  const max = question.configuration ? question.configuration.maximum : undefined;

  const handleClick = (event) => {
    dispatch('answer', { id: question.id, code: question.code, answer: answer });
  }

  const handleKeydown = (event) => {
    if(event.keyCode == 13) dispatch('answer', { id: question.id, code: question.code, answer: answer });
  }

  $: answer = answer || ''
</script>

<style>
  .disabled {
    word-break: break-word;
  }
</style>

{#if enabled}
  <div class='input-group'>
    <textarea class='form-control' class:is-invalid={errors && errors.length} {name} on:keydown={handleKeydown} bind:value={answer} rows=2 />
    <div class='input-group-append'>
      <button disabled={ (answer != undefined && min > 0) || min == undefined ? null : 'disabled' } on:click={handleClick} class="enter-button">{I18n.t('enter')}</button>
    </div>
    {#if errors && errors.length}
      <div class='invalid-feedback'>{errors.join('<br>')}</div>
    {/if}
  </div>
{:else}
  <div class='disabled' on:click={() => dispatch('setCurrent', { id: question.id, code: question.code })}>{answer}</div>
{/if}
