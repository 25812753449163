<script>
  import { createEventDispatcher } from 'svelte';

  import I18n from '../../utilities/I18n.js';
  import { t } from '../../utilities/general.js';
  import BoolQuestion from '../questions/BoolQuestion.svelte';

  export let precondition, 
             value;      
</script>

<section class='questionnaire-item'>
  <span class='question'>{@html t(precondition, 'text')}</span>
  {#if t(precondition, 'help')}
    <p class='question-help'>{@html t(precondition, 'help')}</p>
  {/if}
  <div class='answers'>
    <BoolQuestion on:answer answer={value} question={precondition}>
    </BoolQuestion>
    <input name={`project[preconditions[${precondition.id}][answer]]`} {value} type='hidden'/>
  </div>
  <slot></slot>
</section>

