<script>
  export let question, 
             enabled = false;
             
  import { createEventDispatcher } from 'svelte';
  import I18n from '../../utilities/I18n.js';
  import { t } from '../../utilities/general.js';

  const dispatch = createEventDispatcher();

  let name = `question_${question.code}`;

  const handleClick = (event) => {
    dispatch('answer', { id: question.id, code: question.code, answer: true });
  }

  const text = t(question, 'text');
  const secondary = question.configuration && t(question.configuration, 'secondary_text') ? t(question.configuration, 'secondary_text') : null;
  const help = t(question, 'help');
  const buttonText = question.configuration && t(question.configuration, 'button_text') ? t(question.configuration, 'button_text') : I18n.t('ok');

</script>

<style>
  .buttonbar {
    margin-top: 0;
  }
</style>

<div class='title-question'>
  <h2 class='text'>
    {@html text}
  </h2>
  {#if secondary}
    <h3 class='text text-primary'>
      {@html secondary}
    </h3>
  {/if}
  {#if help}
    <div class='help'>
      {@html help}
    </div>
  {/if}
  {#if enabled}
    <div class='buttonbar'>
      <button class='alert-button' on:click={handleClick}>{buttonText}</button>
    </div>
  {/if}
</div>