import { writable, derived } from "svelte/store";

export const progress = writable(0);
export const project = writable(null);
export const proofItems = writable({});
export const wizardSession = writable(null);

export const substeps = writable([]);
export const activeSubstep = writable(null);
export const defaultSubstep = writable(null);

export const nextSubstep = derived([activeSubstep, substeps], ([$activeSubstep, $substeps]) => {
  let index = $substeps.indexOf($activeSubstep);
  if (++index < $substeps.length) return $substeps[index]
})

export const previousSubstep = derived([activeSubstep, substeps], ([$activeSubstep, $substeps]) => {
  let index = $substeps.indexOf($activeSubstep);
  if (--index >= 0) return $substeps[index]
})

export const activeUsage = writable(null);
