<script>
  import I18n from '../../utilities/I18n.js';

  export let type = 'checkbox', 
             checked, 
             name, 
             label, 
             group, 
             value, 
             icon = '',
             code,
             mainUsage = false;

  let identifier = `${(mainUsage ? 'main' : 'other')}-${code}`;
</script>

<style>
  .usage-selection-option {
    display: inline-block;
    text-align: center;
    color: #dadada;
    padding: 0;
    vertical-align: top;
    overflow-wrap: break-word;
    overflow: hidden;
  }

  .usage-selection-option {
    --iconFillColor: #dadada;
  }

  .usage-selection-option.checked {
    --iconFillColor: var(--primary, #da8b31);
  }

  .usage-selection-option.checked .option-label {
    color: var(--primary, #da8b31);
  }

  .usage-selection-option .option-label {
    width: 100%;
    hyphens: auto;
    word-break: break-word;  /* for browser compatibility */
  }
</style>

{#if type === 'checkbox'}

<div class="usage-selection-option col-6 col-sm-4 col-md-3" class:checked>
  <label for="{identifier}">
    {@html icon}
    <div class="option-label">{label}</div>
    <input id="{identifier}" type="checkbox" bind:checked on:change style="visibility: hidden" />
  </label>
</div>

{:else if type === 'radio'}

<div class="usage-selection-option col-6 col-sm-4 col-md-3" class:checked={group === value}>
  <label for="{identifier}">
    {@html icon}
    <div class="option-label">{label}</div>
    <input id="{identifier}" type="radio" bind:group {value} style="visibility: hidden" on:change />
  </label>
</div>

{/if}
