<script>
  import { t } from "../../utilities/general.js";
  import I18n from "../../utilities/I18n.js";
  import ProofItem from "./ProofItem.svelte";
  import { project as state, proofItems } from "../../stores.js";
  import { getContext } from "svelte";
  import { getCSRFToken } from "../../utilities/html.js";

  export let code, items, finished;
  const { chapter_id, subchapter_id, building_id, floor_usage_id } = items[0];

  let newItems = [];

  const chapters = $state.proof.chapters;
  const paths = getContext("paths");

  async function createItem(_event) {
    const response = await fetch(paths.createProofItem(), {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-CSRF-Token": getCSRFToken(),
        "X-Requested-With": "XMLHttpRequest",
      },
      credentials: "same-origin",
      body: JSON.stringify({
        demand: {
          chapter_id: subchapter_id || chapter_id,
          building_id,
          floor_usage_id,
        },
      }),
    });

    const data = await response.json();

    items.push(data);
    newItems = [...newItems, data];
    $proofItems[data.id] = data;
    $proofItems[data.id].error = data.error;
    finished = data.complete;
  }
</script>

<table class="table table-sm table-proof">
  <thead class="thead-light">
    <tr>
      <th scope="col" class="column">{code && t(chapters[code], "title")}</th>
      <th scope="col" class="column font-weight-normal">
        {I18n.t("activerecord.attributes.proof_answer.requirement_value")}
      </th>
      <th scope="col" class="column font-weight-normal">
        {I18n.t("activerecord.attributes.proof_answer.verification_value")}
      </th>
      <th scope="col">
        <span class="sr-only">{I18n.t("activerecord.attributes.proof_answer.comment")}</span>
      </th>
      <th scope="col">
        <span class="sr-only">{I18n.t("proof.item.skip")}</span>
      </th>
    </tr>
  </thead>
  <tbody>
    {#each items as item (`${item.id}_${item.usage_id}_${item.code}_${item.answer}`)}
      <ProofItem {item} on:help on:answer on:updateItem />
    {/each}
    {#each newItems as item (`${item.id}_${item.usage_id}_${item.code}_${item.answer}`)}
      <ProofItem {item} on:help on:answer on:updateItem />
    {/each}
  </tbody>
</table>

<div class="mb-5">
  <button class="btn btn-sm btn-secondary" on:click={createItem}>
    <span class="fa fa-plus" />
    {I18n.t("proof.item.add")}
  </button>
</div>

<style>
  .table-proof {
    table-layout: fixed;
  }
  .column {
    width: 29.6666%;
  }
  th {
    hyphens: auto;
  }
</style>
