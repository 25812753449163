<svelte:options immutable/>

<script>
  import { createEventDispatcher } from 'svelte';
  import { slide, fade } from 'svelte/transition';

  import I18n from '../../utilities/I18n.js';
  import { isAlert, isTitle, t } from '../../utilities/general.js';

  import AlertQuestion from '../questions/AlertQuestion.svelte';
  import TitleQuestion from '../questions/TitleQuestion.svelte';
  import BoolQuestion from '../questions/BoolQuestion.svelte';
  import ChoiceQuestion from '../questions/ChoiceQuestion.svelte';
  import MultipleChoiceQuestion from '../questions/MultipleChoiceQuestion.svelte';
  import NumberQuestion from '../questions/NumberQuestion.svelte';
  import StringQuestion from '../questions/StringQuestion.svelte';

  const dispatch = createEventDispatcher();

  export let item, 
             enabled = false, 
             errors;

  let showHelp = false;

  const name = `item_${item.code}`;
  let question;
  $: question = item.question;
  let answer;
  $: answer = item.answer;

  let id = `${item.usage_id}_${item.question.code}_${item.answer}`;

  const handleClick = (event) => {
    dispatch('answer', { id: item.question.id, code: item.code, answer: answer });
  }
  const handleAnswer = (event) => {
    dispatch('answer', {...event.detail, buildingId: item.building_id, floorUsageId: item.floor_usage_id });
  }
  const handleSetCurrent = (event) => {
    dispatch('setCurrent', {...event.detail, buildingId: item.building_id, floorUsageId: item.floor_usage_id });
  }
</script>

<style>
  .clickable {
    cursor: pointer;
  }
</style>

<section
  in:fade
  class='questionnaire-item'
  class:alert-item={isAlert(item)} >
  {#if !isAlert(item) && !isTitle(item)}
    <span class='question'>
      {@html t(question, 'text')}
    </span>
    {#if t(question, 'help')}
      <p class='question-help'>
        {#if enabled}
          {@html t(question, 'help')}
        {:else}
            {#if showHelp}
              <div transition:slide>
                {@html t(question, 'help')}
              </div>
            {/if}
          <span
            class='clickable'
            on:click|stopPropagation={() => showHelp = !showHelp}
          >
            <span
              class='text-toggler text-primary'
            >
              {I18n.t(showHelp ? 'less_info' : 'more_info')}
            </span>
            <span class='fa {showHelp ? 'fa-angle-up' :  'fa-angle-right'} text-primary'></span>
          </span>
        {/if}
      </p>
    {/if}
  {/if}
  <div class='answers'>
    {#if question.type == 'AlertQuestion'}
      <AlertQuestion {enabled} {question} {answer} {errors} {id} on:answer={handleAnswer} />
    {:else if question.type == 'TitleQuestion'}
      <TitleQuestion {enabled} {question} {answer} {errors} {id} on:answer={handleAnswer} />
    {:else if question.type == 'BoolQuestion'}
      <BoolQuestion {enabled} {question} {answer} {errors} {id} on:answer={handleAnswer} on:setCurrent={handleSetCurrent} />
    {:else if question.type == 'ChoiceQuestion'}
      <ChoiceQuestion {enabled} {question} {answer} {errors} {id} on:answer={handleAnswer} on:setCurrent={handleSetCurrent} />
    {:else if question.type == 'MultipleChoiceQuestion'}
      <MultipleChoiceQuestion {enabled} {question} {answer} {errors} {id} on:answer={handleAnswer} on:setCurrent={handleSetCurrent} />
    {:else if question.type == 'DecimalQuestion' || question.type == 'IntegerQuestion'}
      <NumberQuestion {enabled} {question} {answer} {errors} {id} on:answer={handleAnswer} on:setCurrent={handleSetCurrent} />
    {:else if question.type == 'StringQuestion'}
      <StringQuestion {enabled} {question} {answer} {errors} {id} on:answer={handleAnswer} on:setCurrent={handleSetCurrent} />
    {:else}
      /* Should not happen */
    {/if}
  </div>
</section>
