<script>
  import { createEventDispatcher } from 'svelte';
  import I18n from '../../utilities/I18n.js';

  export let enabled,
             choices, 
             selected,
             id;

  const dispatch = createEventDispatcher();
  const emitChange = (_event) => { dispatch('change') };

  // check selected answers
  if (selected) {
    let selectedValues = selected.map(c => c.value);

    for (const c of choices) {
      let i = selectedValues.indexOf(c.value);
      if (i >= 0) c.checked = true;
    }
  }

  // keep selection up-to-date
  $: selected = choices.filter(c => c.checked);
</script>

<style>
  .checkbox-group {
    display: flex;
    width: 100%;
  }

  .check_boxes {
    display: flex;
    flex-wrap: wrap;
  }

  .custom-control-label, .question-help {
    padding-right: 2rem;
  }

  .enter-button {
    margin-left: auto;
  }
</style>

<div class="checkbox-group" class:enabled>
  <div class="check_boxes">
    {#each choices as choice, index}
      <div class="custom-control">
        <input class="custom-control-input" 
               type="checkbox"
               value={choice.value}
               id="{id}_{index}"
               bind:checked={choice.checked}>
        <label class="custom-control-label" for="{id}_{index}">{choice.label}</label>
        {#if choice.help}
          <p>
            <small class="question-help">{@html choice.help}</small>
          </p>
        {/if}
      </div>
    {/each}
  </div>
  <button disabled={!selected || selected.length < 1} on:click={emitChange} class='enter-button'>{I18n.t('enter')}</button>
</div>
