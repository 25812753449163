<script>
  import StepNav from './StepNav.svelte';
  import I18n from '../../utilities/I18n.js';
  import Dialog from '../generic/Dialog.svelte';

  export let code;
  
  let showDialog = false;
  const leaveUrl = 'https://heureka.gvb.ch';

  const handleClick = () => {
    if(code && code.length > 0) {
      showDialog = true;
    } else {
      window.location.href = leaveUrl;
    }
  }
</script>

<div class='nav-item step-nav'>
  <div class='circle-wrap'>
    <svg height="36" width="36">
      <circle cx="18" cy="18" r="15" stroke="#ccc" stroke-width="3" fill-opacity="0" />
    </svg>
    <div class="inside-circle">
      <span class='text-gray fa fa-times'></span>
    </div>
  </div>
  <a class='link text-gray' href={leaveUrl} on:click|preventDefault={handleClick}>
    {@html I18n.t('leave')}
  </a>
</div>

<Dialog bind:visible={showDialog}>
  <h3>{I18n.t('leave_project.title')}</h3>
  <p>{@html I18n.t('leave_project.text')}</p>

  <div class='clipboard pointer' data-clipboard-text="{code}" data-placement="bottom" data-title="{I18n.t('current_project_copied_token')}">
      <pre>
        {code}
      </pre>
  </div>
  <a role='button' href={leaveUrl} class='mt-3 btn btn-primary'>{I18n.t('leave')}</a>
</Dialog>