<script>
  import Dialog from '../generic/Dialog.svelte';
  import I18n from '../../utilities/I18n.js';

  export let baseUrl;

  let showDialog = false, enteredCode1;

  const openDialog = () => {
    showDialog = true;
  }

  const submitCode = (e) => {
    if(okDisabled) {
      e.preventDefault();
      return false;
    }
  }

  $: code = `${enteredCode1}`
  $: okDisabled = (!enteredCode1 || enteredCode1.length != 19)

</script>

<div class='nav-item home-nav active'>
  <div class='circle-wrap'>
    <div class="inside-icon">
      <span class="text-primary link far fa-copy"></span>
    </div>
  </div>
  <a class="text-black link" href={'#'} on:click|preventDefault={openDialog}>
    {I18n.t('welcome.existing_project')}
  </a>
</div>


<Dialog bind:visible={showDialog}>
  <h3>{I18n.t('open_project_dialog.title')}</h3>
  <p>{I18n.t('open_project_dialog.text')}</p>
  <form action={`${baseUrl}/${code}/edit`} method='get' on:submit={submitCode}>
    <input class="form-control" bind:value={enteredCode1} maxlength="19" placeholder="XXXX-XXXX-XXXX-XXXX">
    <br/>
    <div class='buttonbar'>
      <input type='submit' class:disabled={okDisabled} role='button' class='continue-button' value={I18n.t('ok')}>
      <button class='back-button' on:click={() => showDialog = false}>{I18n.t('cancel')}</button>
    </div>
  </form>
</Dialog>

