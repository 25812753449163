<svelte:options immutable />

<script>
  /**
   * imports
   */
  import {
    project as state,
    proofItems,
    substeps,
    defaultSubstep,
    activeSubstep,
    nextSubstep,
    previousSubstep,
    activeUsage,
  } from "../../stores.js";

  import { fade, fly, slide } from "svelte/transition";

  import I18n from "../../utilities/I18n.js";
  import { t } from "../../utilities/general.js";

  import Spinner from "../generic/Spinner.svelte";
  import WarningPanel from "../eheureka/WarningPanel.svelte";

  import { getCSRFToken } from "../../utilities/html.js";
  import { ApiPaths, StepPaths } from "../../utilities/paths.js";
  import Select from "../generic/Select.svelte";
  import ProofItem from "../proof/ProofItem.svelte";
  import ProofSection from "../proof/ProofSection.svelte";
  import HelpDialog from "../eheureka/HelpDialog.svelte";
  import { onMount, setContext, tick } from "svelte";
  import InfoPanel from "../eheureka/InfoPanel.svelte";

  /**
   * variables
   */

  export let baseUrl = "",
    apiBaseUrl = "",
    project,
    usages = [],
    previousStep = "precondition";

  const paths = new ApiPaths(apiBaseUrl),
    stepPaths = new StepPaths(baseUrl);

  let promise = true; // Prevent spinner on load
  $state = project;

  let helpDialog,
    showInstructions = $state.proof.show_instructions,
    finished = $state.proof.complete;

  /**
   * context
   */
  setContext("paths", paths);

  /**
   * functions
   */

  async function getProject() {
    console.debug("getProject()", $state);

    const response = await fetch(paths.project(), {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      console.error("getProject Response not ok", response);
      throw new Error(response.statusText);
    }

    const body = await response.json();
    return body;
  }

  async function postAnswer(event) {
    const { id, demand_id, type, answer } = event.detail;

    const response = await fetch(paths.answerProofItem(demand_id), {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-CSRF-Token": getCSRFToken(),
        "X-Requested-With": "XMLHttpRequest",
      },
      credentials: "same-origin",
      body: JSON.stringify({
        answer,
        demand_type: type,
      }),
    });

    const data = await response.json();
    $proofItems[id].answer = { ...data.answer };
    $proofItems[id].error = data.error;
    finished = data.complete;
  }

  async function updateItem(event) {
    const { id, demand_id, text } = event.detail;

    const response = await fetch(paths.updateProofItem(demand_id), {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-CSRF-Token": getCSRFToken(),
        "X-Requested-With": "XMLHttpRequest",
      },
      credentials: "same-origin",
      body: JSON.stringify({
        demand: {
          text,
        },
      }),
    });

    const data = await response.json();
    $proofItems[id].demand = { ...data.demand };
    $proofItems[id].error = data.error;
    finished = data.complete;
  }

  const stepBack = async () => {
    if (!$previousSubstep) return;

    $activeSubstep = $previousSubstep;
    $activeUsage = $activeSubstep.usage;
    scrollToTop();
  };

  const stepForward = async () => {
    if (!$nextSubstep) return;

    $activeSubstep = $nextSubstep;
    $activeUsage = $activeSubstep.usage;
    scrollToTop();
  };

  const scrollToTop = async () => {
    await tick();
    document.querySelector("#proof-heading").scrollIntoView({ left: 0, block: "nearest", behavior: "smooth" });
  };

  const hideHelp = () => {
    showInstructions = false;
  };

  /**
   * after initialization
   */
  onMount(() => {
    $activeSubstep = $substeps[0];
  });
</script>

<div id="proof-heading" class="heading">
  {#if $activeUsage}
    <div class="d-inline-block col-8">
      <h1>{I18n.t(`proof.section.usage`)} {t($activeUsage, "name")}</h1>
    </div>
    <div class="d-inline-flex justify-content-end col-4">
      {@html $activeUsage.icon}
    </div>
  {:else}
    <h1>{I18n.t(`step.${$state.project_kind}.proof`)}</h1>
  {/if}
</div>

{#await promise}
  <Spinner />
{:then id}
  <!-- empty on purpose -->
{:catch error}
  <div class="error">
    <WarningPanel text={error.message || I18n.t("usages_generic_error")} />
  </div>
{/await}

{#if showInstructions}
  <div class="alert alert-info alert-dismissible mb-5" out:fade>
    <InfoPanel text={I18n.t("proof.help_html")} />
    <button type="button" class="close" aria-label={I18n.t("shared.close")} on:click={hideHelp}>
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
{/if}

<div class="proof mb-5">
  {#each Object.entries($state.proof.sections) as [section, usageSections]}
    {#each Object.entries(usageSections) as [usage, sectionChapters]}
      <ProofSection
        {section}
        usage={usages.find((u) => u.code == usage)}
        {sectionChapters}
        on:help={helpDialog.displayHelp}
        on:answer={postAnswer}
        on:answer={hideHelp}
        on:updateItem={updateItem}
        bind:finished
        {scrollToTop} />
    {/each}
  {/each}
</div>

<div id="wizard-buttons" class="buttonbar">
  {#if $nextSubstep}
    <a class="continue-button" href={$nextSubstep.url} on:click={stepForward}>
      {I18n.t("continue_to", { to: $nextSubstep.label })}
    </a>
  {:else}
    <a class="continue-button" href={stepPaths.for("summary")} class:disabled={!finished}>
      {I18n.t("continue")}
    </a>
  {/if}

  {#if $previousSubstep}
    <a class="back-button" on:click={stepBack} href={$previousSubstep.url}>
      {I18n.t("back_to", { to: $previousSubstep.label })}
    </a>
  {:else}
    <a class="back-button" href={stepPaths.for(previousStep)}>{I18n.t("back")}</a>
  {/if}
</div>

<HelpDialog bind:this={helpDialog} />

<style>
  .heading {
    white-space: nowrap;
    margin-bottom: 4rem;
    padding-top: 2rem;
    margin-top: -2rem;
  }
  .heading > * {
    white-space: normal;
    vertical-align: bottom;
    padding: 0;
  }
  .heading h1 {
    margin-bottom: 0;
  }
  .buttonbar {
    margin-bottom: 90px;
  }
  .error {
    margin-top: -2rem;
    margin-bottom: 2rem;
  }
</style>
