<script>
  import { createEventDispatcher, getContext, onMount } from "svelte";
  import I18n from "../../utilities/I18n";
  import Dialog from "../generic/Dialog.svelte";
  import { getCSRFToken } from "../../utilities/html.js";

  export let id = null,
    demand_id = null,
    text = null,
    chapter_id,
    building_id = null,
    floor_usage_id,
    visible = false;

  const paths = getContext("paths");
  const dispatch = createEventDispatcher();

  const commit = (_event) => {
    dispatch("updateItem", { id, demand_id, text });

    visible = false;
  };
</script>

<Dialog bind:visible>
  <h3>{id ? I18n.t("proof.item.edit") : I18n.t("proof.item.add")}</h3>
  <label class="sr-only" for="custom_{id || 'new'}">
    {I18n.t("activerecord.attributes.custom_proof_demand.text")}
  </label>
  <input
    id="custom_{id || 'new'}"
    type="text"
    class="form-control"
    bind:value={text}
    placeholder={I18n.t("proof.item.text_placeholder")} />
  <div class="buttonbar">
    <button class="btn btn-primary" on:click={commit}>{I18n.t("ok")}</button>
  </div>
</Dialog>

<style>
  .form-control {
    padding-left: 0;
    padding-right: 0;
  }
</style>
