<script>
  import StepCircle from "../eheureka/StepCircle.svelte";
  import Dialog from "../generic/Dialog.svelte";
  import I18n from "../../utilities/I18n.js";

  import { progress, substeps, activeSubstep, defaultSubstep } from "../../stores.js";

  export let href,
    active,
    label = "",
    showProgress = false,
    number,
    disabled = true,
    skipped,
    showBackWarning = false,
    enableBackWarning = true,
    icon;

  const fixedClasses = ["nav-item", "step-nav"];
  let allClasses;
  let addedClasses = [];
  if (active) {
    addedClasses.push("active");
  }
  if (!href) {
    addedClasses.push("disabled");
  }
  $: allClasses = [...fixedClasses, ...addedClasses].join(" ");

  const confirmBack = () => {
    window.location.href = href;
  };

  const clickLink = (e) => {
    if (enableBackWarning && number < 3) {
      e.preventDefault();
      showBackWarning = true;
      return false;
    }
  };

  $: showBackWarning = showBackWarning && true;
</script>

<div class={allClasses}>
  <div class="d-flex align-items-baseline">
    <StepCircle
      progress={showProgress && active ? $progress : !active && !disabled}
      progressMax={1}
      {disabled}
      {skipped}>
      <slot>
        {@html icon || ""}
      </slot>
    </StepCircle>
    {#if !disabled && !active}
      <a class="link collapse sidebar-collapse" {href} on:click={clickLink}>
        {@html label}
      </a>
    {:else}
      <span class="link collapse sidebar-collapse">
        {@html label}
      </span>
    {/if}
  </div>
  {#if active && $substeps.length > 0}
    <div class="substeps collapse sidebar-collapse">
      {#each $substeps as substep}
        <div
          class="substep"
          class:active={substep === $activeSubstep || (!$activeSubstep && substep === $defaultSubstep)}>
          {#if substep.clickable}
            <a href={substep.url} on:click={substep.onclick}>{substep.label || ""}</a>
          {:else}
            <span>{substep.label || ""}</span>
          {/if}
        </div>
      {/each}
    </div>
  {/if}
</div>

{#if showBackWarning}
  <Dialog bind:visible={showBackWarning}>
    <p>{I18n.t("confirm.back_step")}</p>
    <div class="buttonbar">
      <button class="continue-button" on:click={confirmBack}>{I18n.t("ok")}</button>
      <button class="back-button" on:click={() => (showBackWarning = false)}>{I18n.t("stay_on_page")}</button>
    </div>
  </Dialog>
{/if}

<style>
  .substeps {
    color: #e4e4e4;
    padding-left: 56px;
  }
  .substep {
    padding-top: 0.25rem;
    padding-bottom: 0.75rem;
    user-select: none;
  }
  .substep:not(.active):not(.active) > a {
    color: #ccc;
  }
  .substep.active > a {
    color: var(--primary, #da8b31);
  }
  .step-icon {
    --iconFillColor: var(--primary, #da8b31);
  }
</style>
