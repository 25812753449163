<svelte:options immutable />

<script>
  import { progress, project as state, substeps, defaultSubstep, activeSubstep } from "../../stores.js";

  import { fade, fly, slide } from "svelte/transition";

  import I18n from "../../utilities/I18n.js";

  import QuestionnaireItem from "../eheureka/QuestionnaireItem.svelte";
  import Spinner from "../generic/Spinner.svelte";
  import WarningPanel from "../eheureka/WarningPanel.svelte";

  import { getCSRFToken } from "../../utilities/html.js";
  import { ApiPaths, StepPaths } from "../../utilities/paths.js";

  import { tick, onMount } from "svelte";
  /// ---

  const newProgress = (project) => {
    if (project.questionnaire) {
      if (project.remaining_question_count == 0) {
        return 1;
      } else if (!project.questionnaire || !project.questionnaire.length) {
        return 0;
      } else {
        return project.questionnaire.length / (project.questionnaire.length + project.remaining_question_count);
      }
    } else {
      return 0;
    }
  };

  function getProject() {
    console.log("getProject()", $state);

    return fetch(paths.project(), {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (!response.ok) {
          console.error("getProject Response not ok", response);
          throw new Error(response.statusText);
        }
        console.log("getProject response", response);
        return response;
      })
      .then((response) => {
        return response.json().then((json) => {
          console.log("getProject result: ", json);
          return json;
        });
      })
      .catch((error) => {
        console.log("Error in getProjectPromise", error);
        return error;
      });
  }

  function postAnswer(questionId, value, buildingId, floorUsageId) {
    console.log("postAnswer", $state, questionId, value);
    return fetch(paths.answerQuestion(questionId), {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-CSRF-Token": getCSRFToken(),
        "X-Requested-With": "XMLHttpRequest",
      },
      credentials: "same-origin",
      body: JSON.stringify({
        answer: {
          value: value,
          building_id: buildingId,
          floor_usage_id: floorUsageId,
        },
      }),
    }).then((response) => {
      if (response.ok) {
        return response.json().then((json) => {
          return {
            answer: json.answer ? json.answer.value : json.answer,
            errors: json.errors,
          };
        });
      } else {
        console.error("response is not ok", response);

        return response.json().then((json) => {
          throw Error(json.error || response.statusText);
        });
      }
    });
  }

  const updateState = ([{ answer, errors }, project]) => {
    console.log("handleAnswer", $state, answer, errors, project);

    $state.current_question.answer = answer;
    $state.current_question.errors = errors;

    if (!errors || errors.length == 0) {
      $state.questionnaire = [...project.questionnaire];
      $state.current_question = project.current_question;
      $state.current_question.answer = $state.current_question.answer;
      $state.remaining_question_count = project.remaining_question_count;

      setCurrentUsage($state.current_question);
    }

    $state = { ...$state };

    console.log("handleAnswerPromise done", $state);
  };

  const setCurrentUsage = (currentQuestion) => {
    let nextUsage = null;

    if (currentQuestion && currentQuestion.usage_id) {
      console.log("current question belongs to usage ", currentQuestion.usage_id);
      nextUsage = usages.find((u) => u.id === currentQuestion.usage_id);
    } else if (currentQuestion === true) {
      nextUsage = $state.questionnaire[$state.questionnaire.length - 1]
        ? usages.find((u) => u.id === $state.questionnaire[$state.questionnaire.length - 1].usage_id)
        : null;
    }

    setUsage(nextUsage);
  };

  const setUsage = (usage) => {
    let previousUsage = currentUsage;
    currentUsage = usage;

    $activeSubstep = usage;
    updateClickableSubsteps();
    updateNextSubstep();
    updatePreviousSubstep();

    tick().then(() => {
      if (previousUsage !== currentUsage) {
        document
          .querySelector("#questionnaire-heading")
          .scrollIntoView({ left: 0, block: "start", behavior: "smooth" });
      } else {
        scrollToCurrentItem();
      }
    });
  };

  const stepBack = () => {
    if (!previousSubstep) return;

    let previousUsageId = previousSubstep.id;

    let previousUsage = usages.find((usage) => usage.id === previousUsageId);
    setUsage(previousUsage);
  };

  const stepForward = () => {
    if (!nextSubstep) return;

    let nextUsageId = nextSubstep.id;
    let nextUsage = usages.find((usage) => usage.id === nextUsageId);
    setUsage(nextUsage);
  };

  const updatePreviousSubstep = () => {
    let currentSubstepIndex = $substeps.indexOf($activeSubstep);
    if (currentSubstepIndex < 1) {
      previousSubstep = null;
      return;
    }

    previousSubstep = $substeps[currentSubstepIndex - 1];
  };

  const updateNextSubstep = () => {
    let nextSubstepIndex;

    if ($activeSubstep) {
      let currentSubstepIndex = $substeps.indexOf($activeSubstep);
      nextSubstepIndex = currentSubstepIndex + 1;
    } else {
      nextSubstepIndex = 1;
    }
    if (nextSubstepIndex >= $substeps.length) {
      nextSubstep = null;
      return;
    }

    nextSubstep = $substeps[nextSubstepIndex].clickable ? $substeps[nextSubstepIndex] : null;
  };

  function handleAnswer(event) {
    const postAnswerPromise = postAnswer(
      event.detail.id,
      event.detail.answer,
      event.detail.buildingId,
      event.detail.floorUsageId
    );
    const newQuestionPromise = postAnswerPromise.then(getProject);

    promise = Promise.all([postAnswerPromise, newQuestionPromise]).then(updateState);
  }

  function handleSetCurrent(event) {
    promise = new Promise((resolve, reject) => {
      console.log("Questionnaire.handleSetCurrent", event, project.questionnaire, project.current_question);

      const id = event.detail.id;
      let newCurrentIdx = 0;
      let idFound = false;
      for (let item of $state.questionnaire) {
        if (item.question.id == id) {
          idFound = true;
          break;
        }
        newCurrentIdx++;
      }

      if (!idFound) {
        console.error("invalid set Current", id);
        reject(`invalid id ${id}`);
      }
      console.log("jusqu'ici tout va bien");

      const newCurrent = $state.questionnaire[newCurrentIdx];
      console.log("newCurrent", newCurrent);
      const newRemainingCount = $state.remaining_question_count + ($state.questionnaire.length - newCurrentIdx);
      console.log("newRemainingCount", newRemainingCount);
      const newQuestionnaire = $state.questionnaire.splice(0, newCurrentIdx);
      console.log("newQuestionnaire", newQuestionnaire);

      $state = {
        ...$state,
        questionnaire: newQuestionnaire,
        current_question: newCurrent,
        remaining_question_count: newRemainingCount,
      };

      if (event.detail.answer != undefined) {
        const postAnswerPromise = postAnswer(
          event.detail.id,
          event.detail.answer,
          event.detail.buildingId,
          event.detail.floorUsageId
        );
        const newQuestionPromise = postAnswerPromise.then(getProject);

        resolve(Promise.all([postAnswerPromise, newQuestionPromise]).then(updateState));
      } else {
        resolve();
      }
    });
  }

  function updateClickableSubsteps() {
    for (const substep of $substeps) {
      substep.clickable = [$state.current_question, ...$state.questionnaire]
        .map((item) => item.usage_id)
        .includes(substep.id);
    }
    $substeps = $substeps;
  }

  function scrollToCurrentItem() {
    let currentItem = document.querySelector(".questionnaire-item:last-child");
    let buttons = document.querySelector("#wizard-buttons");

    if (buttons.getBoundingClientRect().bottom > window.innerHeight - 69) {
      buttons.scrollIntoView({ left: 0, block: "start", behavior: "smooth" });
    }
    addEventListener("scroll", (e) => {
      if (currentItem.getBoundingClientRect().top < 0) {
        currentItem.scrollIntoView({ left: 0, block: "start", behavior: "smooth" });
      }
    });
  }

  function getUsageIds() {
    let usage_ids = [...project.questionnaire.map((qitem) => qitem.usage_id), ...project.building.usage_ids];
    let unique_usage_ids = new Set(usage_ids);
    unique_usage_ids.delete(null);
    return [...unique_usage_ids]; // return as array
  }

  export let baseUrl = "",
    apiBaseUrl = "",
    project,
    usages = [],
    previousStep = "precondition";

  usages = usages.map((usage) => {
    usage.active = false;
    usage.value = usage.id;
    usage.label = usage[`name_${I18n.locale}`];
    return usage;
  });

  const paths = new ApiPaths(apiBaseUrl),
    stepPaths = new StepPaths(baseUrl);

  let promise, questionnaire, currentQuestion, currentUsage, previousSubstep, nextSubstep;

  // Prevent spinner on load
  promise = true; //Promise.resolve(true);

  $state = project;

  let buildingQuestionsSubstep = {
    id: null,
    label: I18n.t(`step.${$state.project_kind}.questions_building`),
    url: "#building",
    onclick: () => setCurrentUsage(),
  };
  let usage_ids = getUsageIds();
  $substeps = [
    buildingQuestionsSubstep,
    ...usage_ids.map((uid) => {
      let usage = usages.find((u) => u.id === uid);
      usage.onclick = () => setUsage(usage);
      usage.url = `#${usage.code}`;
      return usage;
    }),
  ];

  $defaultSubstep = buildingQuestionsSubstep;
  setCurrentUsage($state.current_question);

  console.log("init Questionnaire");
  console.log("onInit: project", $state);
  console.log("onInit: progress", $progress);
  console.log("onInit: currentQuestion", currentQuestion);
  console.log("onInit: questionnaire", questionnaire);
  console.log("init Questionnaire done");

  $: finished = $state.current_question === true;
  $: $progress = newProgress($state);

  $: {
    console.log("update Questionnaire");
    console.log("onUpdate: project", $state);
    console.log("onUpdate: progress", $progress);
    console.log("onUpdate: currentQuestion", $state.current_question);
    console.log("onUpdate: questionnaire", $state.questionnaire);
    console.log("update Questionnaire done");
  }

  onMount(async () => {
    await tick();
    scrollToCurrentItem();
  });
</script>

<div id="questionnaire-heading" class="heading">
  {#if currentUsage}
    <div class="d-inline-block col-8">
      <h1>{I18n.t(`step.${$state.project_kind}.questions_usages`)} {currentUsage.label}</h1>
    </div>
    <div class="d-inline-block col-4">
      <img class="usage-section-icon" src={currentUsage.active_icon_path} alt="" />
    </div>
  {:else}
    <h1>{I18n.t(`step.${$state.project_kind}.questions`)}</h1>
  {/if}
</div>

{#await promise}
  <Spinner />
{:then id}
  <!-- empty on purpose -->
{:catch error}
  <div class="error">
    <WarningPanel text={error.message || I18n.t("usages_generic_error")} />
  </div>
{/await}

<div class="questions mb-5">
  {#each [...$state.questionnaire, $state.current_question] as item, index (item === true ? "" : `${item.usage_id}_${item.question.code}_${item.answer}`)}
    {#if item === true || (currentUsage && currentUsage.id !== item.usage_id) || (!currentUsage && item.usage_id)}
      <!-- empty on purpose -->
    {:else}
      <QuestionnaireItem
        enabled={typeof $state.current_question.question != "undefined" &&
          typeof item.question != "undefined" &&
          $state.current_question.question.code == item.question.code}
        {item}
        answer={item.answer}
        errors={item.errors}
        on:answer={handleAnswer}
        on:setCurrent={(e) =>
          $state.current_question !== true && $state.current_question.question.code == item.question.code
            ? false
            : handleSetCurrent(e)} />
    {/if}
  {/each}
</div>

<div id="wizard-buttons" class="buttonbar">
  {#if nextSubstep}
    <button class="continue-button" on:click={stepForward}>{I18n.t("continue")}</button>
  {:else}
    <a class:disabled={!finished} class="continue-button" href={stepPaths.for("summary")} role="button"
      >{I18n.t("continue")}</a>
  {/if}

  {#if previousSubstep}
    <button class="back-button" on:click={stepBack}>{I18n.t("back")}</button>
  {:else}
    <a class="back-button" href={stepPaths.for(previousStep)} role="button">{I18n.t("back")}</a>
  {/if}
</div>

<style>
  .heading {
    white-space: nowrap;
    margin-bottom: 4rem;
    padding-top: 2rem;
    margin-top: -2rem;
  }
  .heading > * {
    white-space: normal;
    vertical-align: bottom;
    padding: 0;
  }
  .heading h1 {
    margin-bottom: 0;
  }
  .heading .usage-section-icon {
    margin-bottom: -27.5%;
    margin-top: -25%;
  }
  .buttonbar {
    margin-bottom: 90px;
  }
  .error {
    margin-top: -2rem;
    margin-bottom: 2rem;
  }
</style>
