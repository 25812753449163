<script>
  export let closable = true, 
             visible = true;

  const handleClose = () => {
    visible = false;
  }
  $: console.log('update dialog, visible: ', visible);
</script>

<style>
  .dialog {
    background-color: #fefefe;
    margin: 15% auto; /* 15% from the top and centered */
    padding: 40px 24px;
    border: none;
    /* Width could be more or less, depending on screen size */
  }
  .close-header {
    width: 100%;
  }
  .close {
    top: -26px;
    right: -13px;
    position: relative;
    font-size: 17px;
  }
</style>

<!-- keep old implementation in case of customer wants it later -->
{#if visible}
  <div class='overlay' on:click={() => { if(closable) return visible = false }}>
    <div class='dialog' on:click|stopPropagation >
      {#if closable}
        <div class='close-header' on:click={handleClose}>
          <span class='close fa fa-times'></span>
        </div>
      {/if}
      <div>
        <slot></slot>
      </div>
    </div>
  </div>
{/if}
